import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

import { GetLanguageString } from "../../helper/Components";
import { FaListUl } from "react-icons/fa";

const QuickEventReports = ({ history }) => {
  return (
    <div className="wrapper wrapper-content wrapper-custom">
      <div className="ibox">
        <div className="ibox-content panel-body">
          <h3>{<GetLanguageString props="quick_reports_heading" />}</h3>
          <p>{<GetLanguageString props="quick_reports_message" />}</p>
          <Row>
            <Col xl="1" className="text-center">
              <Button
                className="btn-danger dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/eventReports/report`,
                  })
                }
              >
                <FaListUl />
              </Button>
              <h4>
                {<GetLanguageString props="quick_event_reports_button1" />}
              </h4>
            </Col>{" "}
            <Col xl="1" className="text-center">
              <Button
                className="btn-warning dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/eventReports/eventWiseIjtimaAttendance`,
                  })
                }
              >
                <FaListUl />
              </Button>
              <h4>
                {<GetLanguageString props="quick_event_reports_button2" />}
              </h4>
            </Col>{" "}
            <Col xl="1" className="text-center">
              <Button
                className="btn-info dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/eventReports/unitWiseIjtimaAttendance`,
                  })
                }
              >
                <FaListUl />
              </Button>
              <h4>
                {<GetLanguageString props="quick_event_reports_button3" />}
              </h4>
            </Col>
            {/* Events with No Attandance */}
            <Col xl="1" className="text-center">
              <Button
                className="btn-success dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/eventsReport/eventReportWithNoAttendance`,
                  })
                }
              >
                <FaListUl />
              </Button>
              <h4>
                {<GetLanguageString props="quick_event_reports_button4" />}
              </h4>
            </Col>
            <Col xl="1" className="text-center">
              <Button
                className="btn-warning dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/eventReports/rafiqWiseIjtimaAttendance`,
                  })
                }
              >
                <FaListUl />
              </Button>
              <h4>
                {<GetLanguageString props="quick_event_reports_button5" />}
              </h4>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withRouter(QuickEventReports);

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {
  applyClassToCharacters,
  convertToDateString,
  getStringFromJSX,
  isUrdu,
  LoadingSpinner,
} from "../../../../constants/const";
import { GetLanguageString } from "../../../helper/Components";
import { addMember } from "../../ApiMember";
import FormStepDeen from "./FormStepDeen";
import FormStepPersonal from "./FormStepPersonal";
import FormStepTanzeem from "./FormStepTanzeem";
import FormStepTasurat from "./FormStepTasurat";
import SearchAutoComplete from "../../../shared/SearchAutoComplete";
import { useTranslation } from "react-i18next";
import { BiSearchAlt } from "react-icons/bi";
import { GetCustomStyles, GetSelectedLanguage } from "../../../helper/Method";
import { RxCross2 } from "react-icons/rx";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { MdDoneAll } from "react-icons/md";
import { toast } from "react-toastify";
import ResponsibilityErrorMessage from "../ResponsibilityErrorMessage";
import swal from "sweetalert";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const MuavaneenResponsibilityRequestForm = ({ responsibilityValues, history }) => {
  const { t } = useTranslation();
  const selectedLanguage = GetSelectedLanguage();
  const [currentStep, setCurrentStep] = useState(1);
  const [searchByName, setSearchByName] = useState(false);
  const [searchBySerialNumber, setSearchBySerialNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [displayMessageModal, setDisplayMessageModal] = useState(false);
  const notify = (string) => toast.success(string);
  const [errorMessages, setErrorMessages] = useState([]);
  const [members, setMembers] = useState([]);
  const [name, setName] = useState("");
  const [regNoForSearch, setRegNoForSearch] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSearchByRegNo, setLoadingSearchByRegNo] = useState(false);

  const [values, setValues] = useState({
    memberRegNo: "",
    memberName: "",
    memberFatherName: "",
    memberMaritalStatus: null, // Assuming this is a model or object; set to null or an initial SelectListModel
    memberAge: "",
    proposedResponsibility: responsibilityValues.responsibility, // Assuming this is also a SelectListModel
    previousAssignedResponsibilityMemberId: "",
    previousAssignedResponsibilityMember: "",
    previousAssignedResponsibilityMemberRegNo: "",
    additionalResponsibilities: [], // Empty array for list
    previousResponsibilities: [], // Empty array for list
    joiningDate: null, // Date can be null initially
    multazimDate: null,
    multazimCourseDate: null,
    isMultazimNisaabComplete: false, // Assuming this is a boolean, set to false
    nuqabaCourseDate: null,
    umraNuqabaCourse: null,
    isZimedaranNisaabComplete: false,
    mudariseenCourse: null,
    mudariseenRefresherCourse: null,
    isMudariseenNisaabComplete: false,
    arabicGrammarTestStatus: {},
    tajweebTestStatus: {},
    isReadNizamUlAmalAndRahnumaAsool: false,
    deeniEducation: "",
    duniyawiEducation: "",
    knowledgeAboutAccounts: "",
    knowledgeAboutComputerUse: "",
    knowledgeAboutFinancialManual: "",
    ibadaat: "",
    ikhlaqiyat: "",
    muamlaat: "",
    literatureRevision: "",
    tilawatQuran: "",
    hifzQuran: "",
    tarjumaQuran: "",
    tafseeerQuran: "",
    infiradiDawat: "",
    attendanceInIjtimayiDawatiActivities: "",
    darsOTadrees: "",
    infaaqMaalWaqt: "",
    ghareluUsra: "",
    mutabadilTarbiat: "",
    ateendanceInIjtimaat: "",
    lfcReports: "",
    useOfElectronicMedia: "",
    avoidGhairMasnoonRasomaat: "",
    isSharayiPardaInHome: false, // Boolean value
    isSharayiPardaOutsideHome: false, // Boolean value
    avoidAttendingMehfilGhairMehram: "",
    physicalAppearance: "",
    wayOfLiving: "",
    sourceOfIncome: "",
    avoidHaram: "",
    maliLainDainMuamlat: "",
    otherInformation: "",
    dateOfRequest: new Date(),
    muqamiAmeerName: "",
    muqamiAmeerFeedback: "",
    ameerHalqaName: "",
    ameerHalqaFeedback: "",
    nazimAllaTabsaraOTasuraat: "",
    nazimAllaName: "",
  });

  useEffect(() => {
    if (name !== "" && !name.includes("/")) {
      setLoadingSearch(true);
      const timeoutId = setTimeout(() => {
        addMember()
          .getUnitParticipants(responsibilityValues.unit.value, name)
          .then((res) => {
            setMembers(res.data);
            setLoadingSearch(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingSearch(false);
          });
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    setMembers([]);
    setLoadingSearch(false);
  }, [responsibilityValues, name]);

  const handleOnSelect = (item) => {
    var str = item?.name;
    let remove = str.split(" - ").pop();
    let requiredName = str.replace(" - " + remove, "   ");
    setName("");
    // setMemberId(item.id);
    setValues({
      ...values,
      previousAssignedResponsibilityMemberRegNo: item.memberRegNo,
      previousAssignedResponsibilityMember: requiredName,
      previousAssignedResponsibilityMemberId: item.id,
    });
    setSearchByName(false);
    setMembers([]);
  };
  const handleSearchByRegNo = useCallback(() => {
    if (!regNoForSearch.trim()) return; // Prevent empty search
    setLoadingSearchByRegNo(true); // Show spinner
    setTimeout(() => {
      addMember()
        .getUnitParticipantsByRegNo(
          responsibilityValues.unit.value,
          regNoForSearch
        )
        .then((res) => {
          setValues({
            ...values,
            previousAssignedResponsibilityMemberRegNo: res.data.memberRegNo,
            previousAssignedResponsibilityMember: res.data.name,
            previousAssignedResponsibilityMemberId: res.data.id,
          });
          setSearchBySerialNumber(false);
          setLoadingSearchByRegNo(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingSearchByRegNo(false);
        });
    }, 1500);
  }, [regNoForSearch, responsibilityValues, values]);

  useEffect(() => {
    setLoading(true);
    addMember()
      .MemberResponsibilityRequestMuavinFormData(
        responsibilityValues.memberId,
        responsibilityValues.memberRegNo,
        responsibilityValues.responsibility.value,
        responsibilityValues.unit.value,
        responsibilityValues.displayName
          ? responsibilityValues.displayName
          : null
      )
      .then((res) => {
        setValues(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [responsibilityValues]);

  const nextStep = () => {
    if (currentStep < 4) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckBoxes = (e) => {
    const { name, value } = e.target;
    let val = value === "true";
    setValues({
      ...values,
      [name]: val,
    });
  };
  const handleSubmit = () => {
    values.proposedResponsibility = responsibilityValues.responsibility;
    values.dateOfRequest = convertToDateString(new Date());

    responsibilityValues.muavinRequest = values;
    setButtonDisable(true);
    setLoading(true);
    addMember()
      .addMemberResponsibility(responsibilityValues, false)
      .then((res) => {
        if (res.data.requestExists) {
          swal({
            title: t("member__request_exists_for_assign_responsibility"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "error",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          history.goBack();
          setLoading(false);
          setButtonDisable(false);
        } else if (res.data === "Invalid Data") {
          notify(res.data);
          setLoading(false);
          setButtonDisable(false);
        } else if (res.data.cannotAssignResponsibility) {
          swal({
            title: t(
              "member__member_responsibility_error_cannot_assign_resposnibility"
            ),
            icon: "error",
            buttons: "OK",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
          });
          setButtonDisable(false);
          setLoading(false);
        } else if (
          !res.data.thisMemberHaveResponsibility &&
          !res.data.otherMemberHaveResponsibility &&
          !res.data.isUsraMavin
        ) {
          swal({
            title: t("member__request_success_for_assign_responsibility"),
            icon: "success",
            buttons: "OK",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
          });
          history.goBack();
          setLoading(false);
          setButtonDisable(false);
        } else {
          setErrorMessages(res.data);
          setDisplayMessageModal(true);
          setLoading(false);
          setButtonDisable(false);
        }
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err);
        setLoading(false);
        setButtonDisable(false);
      });
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <FormStepPersonal
            setValues={setValues}
            values={values}
            handleCheckBoxes={handleCheckBoxes}
          />
        );
      case 2:
        return (
          <FormStepDeen
            setValues={setValues}
            values={values}
            handleCheckBoxes={handleCheckBoxes}
          />
        );
      case 3:
        return (
          <FormStepTanzeem
            values={values}
            setValues={setValues}
            handleCheckBoxes={handleCheckBoxes}
            handleInputChange={handleInputChange}
          />
        );
      case 4:
        return <FormStepTasurat values={values} setValues={setValues} />;
      default:
        return null;
    }
  };

  return (
    <div className="ibox-content">
      {!loading ? (
        <>
          {displayMessageModal ? (
            <ResponsibilityErrorMessage
              setDisplayMessageModal={setDisplayMessageModal}
              data={errorMessages}
              displayName={responsibilityValues.displayName}
            />
          ) : null}
          <Row>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  <GetLanguageString props={"common_name"} />
                </Label>
                <Input
                  type="text"
                  name="memberName"
                  disabled
                  className={
                    isUrdu(values.memberName) ? "ur no-drag" : "no-drag"
                  }
                  value={values.memberName}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  <GetLanguageString props={"member_reg_number"} />
                </Label>
                <Input
                  type="text"
                  name="memberRegNo"
                  disabled
                  className={
                    isUrdu(values.memberRegNo) ? "ur no-drag" : "no-drag"
                  }
                  value={getStringFromJSX(
                    applyClassToCharacters(values.memberRegNo, "ur")
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  <GetLanguageString
                    props={"member__request_proposed_responsibility"}
                  />
                </Label>
                <Input
                  type="text"
                  name="proposedResponsibility"
                  disabled
                  className={"no-drag ur"}
                  value={
                    responsibilityValues.displayName
                      ? `(${responsibilityValues.displayName})` +
                        responsibilityValues.responsibility.label
                      : responsibilityValues.responsibility.label
                  }
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Col>
            {!searchByName ? (
              <>
                <Col md="4" style={{ width: "38%" }}>
                  <FormGroup>
                    <Label className="col-form-label">
                      <GetLanguageString
                        props={"member__request_previous_member_responsibility"}
                      />
                    </Label>
                    <Input
                      type="text"
                      name="previousAssignedResponsibilityMember"
                      disabled
                      className={
                        isUrdu(values.previousAssignedResponsibilityMember)
                          ? "ur no-drag"
                          : "no-drag"
                      }
                      value={getStringFromJSX(
                        applyClassToCharacters(
                          values.previousAssignedResponsibilityMember,
                          "ur"
                        )
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="1" style={{ width: "12%" }}>
                  <Button
                    size={"m"}
                    style={{ marginTop: "35px" }}
                    color="default"
                    onClick={() => {
                      setSearchByName(!searchByName);
                    }}
                  >
                    <BiSearchAlt
                      size={15}
                      className={GetCustomStyles().fa_style}
                    />
                    <span>
                      {<GetLanguageString props="member_search_by_name" />}
                    </span>
                  </Button>
                </Col>
              </>
            ) : (
              <Col md="6" style={{ marginTop: 33 }}>
                <SearchAutoComplete
                  members={members}
                  setMembers={setMembers}
                  setName={setName}
                  setSearchByName={setSearchByName}
                  name={name}
                  type={"rufqa"}
                  loading={loadingSearch}
                  placeholder={t("member_search_by_name")}
                  handleOnSelect={handleOnSelect}
                  calledFrom="responsibility"
                />
              </Col>
            )}
            {!searchBySerialNumber ? (
              <>
                <Col md="4" style={{ width: "38%" }}>
                  <FormGroup>
                    <Label className="col-form-label">
                      <GetLanguageString
                        props={
                          "member__request_previous_member_regno_responsibility"
                        }
                      />
                    </Label>
                    <Input
                      type="text"
                      name="previousAssignedResponsibilityMemberRegNo"
                      disabled
                      value={values.previousAssignedResponsibilityMemberRegNo}
                    />
                  </FormGroup>
                </Col>
                <Col md="1" style={{ width: "12%" }}>
                  <Button
                    size={"m"}
                    style={{ marginTop: "35px" }}
                    color="default"
                    onClick={() => {
                      setSearchBySerialNumber(!searchBySerialNumber);
                    }}
                  >
                    <BiSearchAlt
                      size={15}
                      className={GetCustomStyles().fa_style}
                    />
                    <span>
                      {<GetLanguageString props="member_search_by_regno" />}
                    </span>
                  </Button>
                </Col>{" "}
              </>
            ) : (
              <Col md="6">
                {" "}
                <Label className="col-form-label">
                  <GetLanguageString props={"member_search_by_regno"} />
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="memberRegNo"
                    className="no-drag"
                    value={regNoForSearch}
                    onChange={(e) => setRegNoForSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchByRegNo();
                      }
                    }}
                  />
                  <InputGroupText
                    onClick={() => setSearchBySerialNumber(false)}
                  >
                    {" "}
                    <RxCross2 size={18} />
                  </InputGroupText>
                  {loadingSearchByRegNo && (
                    <InputGroupText style={{ backgroundColor: "#FFFFFF" }}>
                      <Spinner size="sm" color="dark" />
                    </InputGroupText>
                  )}
                </InputGroup>
              </Col>
            )}
          </Row>
          <hr></hr>
          <Row className="mb-4">
            {[1, 2, 3, 4].map((step) => (
              <Col key={step}>
                <Button
                  block
                  className="text-left position-relative py-2"
                  style={{
                    backgroundColor:
                      currentStep >= step ? "#507881" : "#5d8f9b", // Darker for active, lighter for inactive
                    borderColor: currentStep >= step ? "#507881" : "#5d8f9b", // Uniform border color
                    color: "#FFFFFF", // White text for all
                    cursor: currentStep < step ? "not-allowed" : "pointer",
                    transition: "all 0.3s ease-in-out",
                    boxShadow:
                      currentStep >= step
                        ? "0 4px 15px rgba(80, 120, 129, 0.4)" // Subtle shadow for active
                        : "none",
                  }}
                  onMouseEnter={(e) => {
                    if (currentStep >= step) {
                      e.target.style.backgroundColor = "#33565E"; // Slightly darker on hover
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (currentStep >= step) {
                      e.target.style.backgroundColor = "#507881"; // Revert to original color
                    }
                  }}
                  disabled={currentStep < step}
                >
                  <span
                    className="font-weight-bold ur"
                    style={{ fontSize: 18 }}
                  >
                    {step === 1
                      ? "ذاتی اعتبار سے"
                      : step === 2
                      ? "دینی اعتبار سے"
                      : step === 3
                      ? "تنظیمی اعتبار سے"
                      : "امیر کے تاثرات"}
                  </span>
                </Button>
              </Col>
            ))}
          </Row>

          <div className="ibox-content p-4 mb-4" style={{ minHeight: "200px" }}>
            {renderStepContent()}
          </div>

          {/* Navigation */}
          <div className="d-flex justify-content-between">
            <Button
              color="default"
              onClick={prevStep}
              disabled={currentStep === 1}
              className="px-4"
            >
              {selectedLanguage === "ur" ? (
                <IoArrowForwardSharp
                  color={currentStep === 1 ? "grey" : "#507881"}
                  size={15}
                  style={{ marginLeft: 5, marginBottom: 1 }}
                />
              ) : (
                <IoArrowBackSharp
                  color="#507881"
                  size={15}
                  style={{ marginRight: 5, marginBottom: 1 }}
                />
              )}
              <GetLanguageString props={"Previous"} />
            </Button>
            {currentStep === 4 ? (
              <Button
                color={"default"}
                onClick={handleSubmit}
                disabled={buttonDisable}
                className="px-4"
              >
                <MdDoneAll
                  color={currentStep === 1 ? "grey" : "#507881"}
                  size={17}
                  style={{ marginRight: 5, marginBottom: 1 }}
                />
                <GetLanguageString props={"Submit"} />
              </Button>
            ) : (
              <Button
                color={"default"}
                onClick={nextStep}
                disabled={currentStep === 4}
                className="px-4"
              >
                <GetLanguageString props={"Next"} />
                {selectedLanguage === "ur" ? (
                  <IoArrowBackSharp
                    color={currentStep === 1 ? "grey" : "#507881"}
                    size={15}
                    style={{ marginRight: 5, marginBottom: 1 }}
                  />
                ) : (
                  <IoArrowForwardSharp
                    color="#507881"
                    size={15}
                    style={{ marginLeft: 5, marginBottom: 1 }}
                  />
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(MuavaneenResponsibilityRequestForm);

import React, { useState, useEffect } from "react";
import { Input, Row, Table } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { isUrdu } from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";

const LetterForCounsellingDataTable = ({
  questionsList,
  setQuestionsList,
  setErrorMessage,
  errorMessage,
  letterColor,
  calledAs,
}) => {
  const [focusedRow, setFocusedRow] = useState(0); // Track focused row
  const selectedLanguage = GetSelectedLanguage();

  const handleRating = (index, value) => {
    let updatedQuestions = [...questionsList];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      rating: value,
      errorColor: "#ffffff",
    };

    if (updatedQuestions.every((q) => q.rating !== 0)) {
      setErrorMessage({ ...errorMessage, questions: "" });
    }

    setQuestionsList(updatedQuestions);
  };

  useEffect(() => {
    if (calledAs === "view") return;

    setQuestionsList((prevQuestions) => {
      let updatedQuestions = [...prevQuestions];
      if (!updatedQuestions[0]?.rating && calledAs === "add") {
        updatedQuestions[0].rating = 1; // Ensure first row has a rating
      }
      return updatedQuestions;
    });

    const handleKeyDown = (event) => {
      let updatedQuestions = [...questionsList];

      if (event.key === "ArrowUp") {
        setFocusedRow((prev) => {
          const newRow = Math.max(0, prev - 1);
          if (!updatedQuestions[newRow].rating) {
            updatedQuestions[newRow].rating = 1; // Default to first rating
          }
          setQuestionsList(updatedQuestions);
          return newRow;
        });
      } else if (event.key === "ArrowDown") {
        setFocusedRow((prev) => {
          const newRow = Math.min(questionsList.length - 1, prev + 1);
          if (!updatedQuestions[newRow].rating) {
            updatedQuestions[newRow].rating = 1; // Default to first rating
          }
          setQuestionsList(updatedQuestions);
          return newRow;
        });
      } else if (event.key === "ArrowLeft") {
        if (selectedLanguage === "ur") {
          handleRating(
            focusedRow,
            Math.min(4, (questionsList[focusedRow]?.rating || 1) + 1)
          );
        } else {
          handleRating(
            focusedRow,
            Math.max(1, (questionsList[focusedRow]?.rating || 1) - 1)
          );
        }
      } else if (event.key === "ArrowRight") {
        if (selectedLanguage === "ur") {
          handleRating(
            focusedRow,
            Math.max(1, (questionsList[focusedRow]?.rating || 1) - 1)
          );
        } else {
          handleRating(
            focusedRow,
            Math.min(4, (questionsList[focusedRow]?.rating || 1) + 1)
          );
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [focusedRow, questionsList, calledAs]);

  const renderRatingCell = (index, item, ratingValue) => (
    <td
      className="text-center"
      onClick={() =>
        calledAs === "view" ? null : handleRating(index, ratingValue)
      }
      style={{
        cursor: calledAs === "view" ? "default" : "pointer",
        backgroundColor:
          focusedRow === index && calledAs !== "view"
            ? "#eaeaea"
            : "transparent", // Highlight focused row
      }}
    >
      <Input
        type="radio"
        checked={item.rating === ratingValue}
        readOnly
        style={{
          pointerEvents: "none",
          transform: "scale(1.2)",
          marginRight: "10px",
          cursor: "pointer",
          border: `2px solid ${letterColor}`,
          backgroundColor:
            item.rating === ratingValue ? letterColor : "transparent",
        }}
      />
    </td>
  );

  return (
    <>
      <Table responsive bordered>
        <thead style={{ backgroundColor: letterColor }}>
          <tr>
            {[
              "settings_letter_for_counselling_question_q_number",
              "settings_letter_for_counselling_questions",
              "تسلی بخس",
              "بہتر ہوئی ہے",
              "کوشش جاری ہے",
              "ارادہ ہے",
            ].map((text, i) => (
              <th
                key={i}
                className={`${isUrdu(text) ? "ur" : ""} ${
                  text === "settings_letter_for_counselling_questions"
                    ? ""
                    : "text-center"
                }`}
                style={{ fontSize: 15, backgroundColor: letterColor }}
              >
                <GetLanguageString props={text} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questionsList.map((item, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: item.errorColor,
                outline:
                  focusedRow === index && calledAs !== "view"
                    ? "1px solid " + letterColor
                    : "none",
              }}
            >
              <td className="text-center" style={{ fontSize: 15 }}>
                {item.questionNumber}
              </td>
              <td className="ur" style={{ fontSize: 17 }}>
                {item.questionTitle}
              </td>
              {[1, 2, 3, 4].map((rating) =>
                renderRatingCell(index, item, rating)
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {Object.values(errorMessage).map(
        (msg, i) =>
          msg && (
            <Row key={i}>
              <h5 className="text-error">
                * {<GetLanguageString props={msg} />}
              </h5>
            </Row>
          )
      )}
    </>
  );
};

export default LetterForCounsellingDataTable;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonGroup, Table } from "reactstrap";
import swal from "sweetalert";

import {
  convertUTCToLocalTime,
  isUrdu,
  LoadingSpinner,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";

import { BiSolidEdit } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { TbTrash } from "react-icons/tb";
import AddResponsibilityByUnit from "./AddResponsibilityByUnit";
import DeleteResponsibility from "./DeleteResponsibility";
import EditMemberResponsibility from "./EditMemberResponsibility";
import MemberResponsibilityRenewal from "./MemberResponsibilityRenewal";

const MemberResponsibilityList = ({
  tab8,
  memberId,
  unitId,
  unitName,
  addNewRafiq,
  setUpdatedDetails,
  memberTypeId,
}) => {
  const [responsibilityStateChange, setResponsibilityStateChange] =
    useState(false);
  const [loading1, setLoading1] = useState(false);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unitNames, setUnitNames] = useState([]);
  const [deleteResponsibility, setDeleteResponsibility] = useState({});
  const [editResponsibility, setEditResponsibility] = useState({});
  const [addResponsibilityModal, setAddResponsibilityModal] = useState(false);
  const [memberResponsibilityId, setMemberResponsibilityId] = useState();
  const [
    memberResponsibilityRenewalModal,
    setMemberResponsibilityRenewalModal,
  ] = useState(false);
  const [editResponsibilityModal, setEditResponsibilityModal] = useState(false);
  const [
    endMemberResponsibilityDateModal,
    setEndMemberResponsibilityDateModal,
  ] = useState(false);

  const { t } = useTranslation();
  const notify = (string) => toast.success(string);
  const customTableStyles = {
    border: "1px solid #DEE2E6",
  };
  useEffect(() => {
    if (tab8 === "active") {
      setLoading(true);
      addMember()
        .getMemberResponsibilityList(memberId, true)
        .then((res) => {
          let tempNames = [];
          res.data.forEach((element) => {
            if (element.unit.value !== 0) {
              tempNames.push({
                names: element.unit?.label.split("،"),
              });
            }
          });
          setUnitNames(tempNames);
          setUnits(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [memberId, unitId, unitName, responsibilityStateChange, tab8]);

  const handleOnDelete = (record) => {
    if (record.isCurrent) {
      setDeleteResponsibility(record);
      setEndMemberResponsibilityDateModal(true);
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          let responsbilities = [];
          responsbilities.push(record);
          addMember()
            .deleteMemberResponsibility(responsbilities)
            .then((res) => {
              if (
                res.data === "Updated" ||
                res.data === "Permanently Deleted!"
              ) {
                swal({
                  title: t(
                    "member__member_responsibility_added_success_message"
                  ),
                  icon: "success",
                  buttons: "OK",
                  className: GetSelectedLanguage() === "en" ? "" : "ur",
                });
                setLoading(false);
                setResponsibilityStateChange((prevState) => !prevState);
              } else if (
                res.data === "Your request for modification is sent."
              ) {
                notify(res.data);
                setLoading(false);
              }
            })
            .catch((err) => {
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  return (
    <>
      {editResponsibilityModal ? (
        <EditMemberResponsibility
          addNewRafiq={addNewRafiq}
          editResponsibility={editResponsibility}
          loading1={loading1}
          unitId={editResponsibility.unit.value}
          memberId={memberId}
          setUpdatedDetails={setUpdatedDetails}
          editResponsibilityModal={editResponsibilityModal}
          setEditResponsibilityModal={setEditResponsibilityModal}
          setResponsibilityStateChange={setResponsibilityStateChange}
        />
      ) : null}
      {addResponsibilityModal ? (
        <AddResponsibilityByUnit
          addNewRafiq={addNewRafiq}
          loading1={loading1}
          unitId={unitId}
          memberId={memberId}
          setUpdatedDetails={setUpdatedDetails}
          addResponsibilityModal={addResponsibilityModal}
          setAddResponsibilityModal={setAddResponsibilityModal}
          setResponsibilityStateChange={setResponsibilityStateChange}
          memberTypeId={memberTypeId}
        />
      ) : null}
      {endMemberResponsibilityDateModal ? (
        <DeleteResponsibility
          setResponsibilityStateChange={setResponsibilityStateChange}
          setEndMemberResponsibilityDateModal={
            setEndMemberResponsibilityDateModal
          }
          deleteResponsibility={deleteResponsibility}
          setUpdatedDetails={setUpdatedDetails}
        />
      ) : null}
      {memberResponsibilityRenewalModal ? (
        <MemberResponsibilityRenewal
          memberResponsibilityId={memberResponsibilityId}
          setMemberResponsibilityRenewalModal={
            setMemberResponsibilityRenewalModal
          }
          setResponsibilityStateChange={setResponsibilityStateChange}
        />
      ) : null}
      <div className="panel-body">
        <div className="panel panel-primary">
          <div className="panel-heading">
            <Button
              color="default"
              size="xs"
              className={GetCustomStyles().btn_style}
              onClick={() => {
                setLoading1(true);
                setAddResponsibilityModal(true);
              }}
            >
              <IoAdd color="green" size={18} /> &nbsp;
              <span style={{ color: "black" }}>
                <GetLanguageString props="settings_add_responsibility" />
              </span>
            </Button>
            {
              <GetLanguageString props="member_select_unit_and_responsibilities" />
            }
          </div>
          <div className="panel-body">
            {!loading ? (
              units?.length !== 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_common_unit_name" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_common_responsibility" />
                        }
                      </th>
                      <th>
                        {<GetLanguageString props="member_report_from" />}
                      </th>
                      <th>{<GetLanguageString props="member_report_to" />}</th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_renewal_date" />
                        }
                      </th>
                      <th>
                        {<GetLanguageString props="member_reference_note" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {units?.map((record, index) => {
                      return record.renewalDates.length !== 0 ? (
                        record.renewalDates?.map((item, index1) => {
                          return (
                            <tr key={index}>
                              {index1 === 0 ? (
                                <>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                    className="ur"
                                  >
                                    <ol className="breadcrumb">
                                      {unitNames[index]?.names?.map(
                                        (item, index) => {
                                          return (
                                            <li
                                              className={
                                                GetSelectedLanguage() === "ur"
                                                  ? "breadcrumb-item-ur"
                                                  : "breadcrumb-item"
                                              }
                                              key={index}
                                            >
                                              {item}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ol>
                                  </td>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                    className="ur"
                                  >
                                    {record.displayName !== "" ? (
                                      <span>
                                        ({record.displayName}){" "}
                                        {record.responsibilityName}{" "}
                                      </span>
                                    ) : (
                                      record.responsibilityName
                                    )}
                                  </td>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                  >
                                    {record.responsibilityDate
                                      ? moment(
                                          convertUTCToLocalTime(
                                            record.responsibilityDate
                                          )
                                        ).format("DD/MM/yyyy")
                                      : "-"}
                                  </td>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                  >
                                    {record.responsibilityEndDate
                                      ? moment(
                                          convertUTCToLocalTime(
                                            record.responsibilityEndDate
                                          )
                                        ).format("DD/MM/yyyy")
                                      : "-"}
                                  </td>
                                </>
                              ) : null}
                              <td
                                style={{
                                  borderBottom: "1px solid #DEE2E6",
                                  borderRight: "1px solid #DEE2E6",
                                }}
                              >
                                {item
                                  ? moment(convertUTCToLocalTime(item)).format(
                                      "DD/MM/yyyy"
                                    )
                                  : "-"}
                              </td>

                              {index1 === 0 ? (
                                <>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                    className={
                                      isUrdu(record.referenceNote) ? "ur" : ""
                                    }
                                  >
                                    {record?.referenceNote}
                                  </td>
                                  <td
                                    style={customTableStyles}
                                    rowSpan={record.renewalDates.length}
                                    className="text-center"
                                  >
                                    <ButtonGroup>
                                      {record.renewalRequired &&
                                      record.isCurrent ? (
                                        <Button
                                          color="white"
                                          size="sm"
                                          onClick={() => {
                                            setMemberResponsibilityId(
                                              record.memberResponsibilityId
                                            );
                                            setMemberResponsibilityRenewalModal(
                                              true
                                            );
                                          }}
                                        >
                                          <i class="fa fa-refresh"></i> &nbsp;
                                          {
                                            <GetLanguageString props="member_responsibility_renew" />
                                          }
                                        </Button>
                                      ) : null}
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={() => {
                                          setEditResponsibility(record);
                                          setEditResponsibilityModal(true);
                                        }}
                                      >
                                        <BiSolidEdit size={15} />
                                        &nbsp;
                                        {
                                          <GetLanguageString props="common_edit" />
                                        }
                                      </Button>{" "}
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={() => {
                                          setDeleteResponsibility(record);
                                          setEndMemberResponsibilityDateModal(
                                            true
                                          );
                                        }}
                                      >
                                        <TbTrash size={15} /> &nbsp;
                                        {
                                          <GetLanguageString props="common_delete" />
                                        }
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </>
                              ) : null}
                            </tr>
                          );
                        })
                      ) : (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: record.isCurrent ? "#AFF1DD" : "",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td className="ur">
                            <ol
                              className="breadcrumb"
                              style={{
                                backgroundColor: record.isCurrent
                                  ? "#AFF1DD"
                                  : "",
                              }}
                            >
                              {unitNames[index]?.names?.map((item, index) => {
                                return (
                                  <li
                                    className={
                                      GetSelectedLanguage() === "ur"
                                        ? "breadcrumb-item-ur"
                                        : "breadcrumb-item"
                                    }
                                    key={index}
                                  >
                                    {item}
                                  </li>
                                );
                              })}
                            </ol>
                          </td>
                          <td className="ur">
                            {record.displayName !== "" ? (
                              <span>
                                ({record.displayName}){" "}
                                {record.responsibilityName}{" "}
                              </span>
                            ) : (
                              record.responsibilityName
                            )}
                          </td>
                          <td>
                            {record.responsibilityDate
                              ? moment(
                                  convertUTCToLocalTime(
                                    record.responsibilityDate
                                  )
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td>
                            {record.responsibilityEndDate
                              ? moment(
                                  convertUTCToLocalTime(
                                    record.responsibilityEndDate
                                  )
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td>{"-"}</td>
                          <td
                            className={isUrdu(record.referenceNote) ? "ur" : ""}
                          >
                            {record?.referenceNote}
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              {record.renewalRequired && record.isCurrent ? (
                                <Button
                                  color="white"
                                  size="sm"
                                  onClick={() => {
                                    setMemberResponsibilityId(
                                      record.memberResponsibilityId
                                    );
                                    setMemberResponsibilityRenewalModal(true);
                                  }}
                                >
                                  <i class="fa fa-refresh"></i> &nbsp;
                                  {
                                    <GetLanguageString props="member_responsibility_renew" />
                                  }
                                </Button>
                              ) : null}
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => {
                                  setEditResponsibility(record);
                                  setEditResponsibilityModal(true);
                                }}
                              >
                                <i class="fa fa-edit"></i>&nbsp;
                                {<GetLanguageString props="common_edit" />}
                              </Button>{" "}
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => {
                                  handleOnDelete(record);
                                }}
                              >
                                <i class="fa fa-trash"></i> &nbsp;
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <>
                  <h4 className="text-error text-center">
                    {
                      <GetLanguageString props="common_no_result_responsibilities" />
                    }
                  </h4>
                </>
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MemberResponsibilityList);

import CryptoJS from "crypto-js";
const secretKey = "w4WtPpj7mx4GraXvMHqvuIsaoTcMpqpA";


export const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return encodeURIComponent(encryptedData); // Encode data to be URL-safe
};

export const decryptData = (encryptedData) => {
  try {
    const decodedData = decodeURIComponent(encryptedData); // Decode URL-encoded data
    const bytes = CryptoJS.AES.decrypt(decodedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

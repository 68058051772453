import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import useForm from "../../shared/useForm";
import { ApiUnit } from "../../unit/ApiUnit";
import { ApiLFC } from "../ApiLFC";

const SearchListToSendLFC = ({
  setSearchButton,
  setSearchValues,
  searchValues,
  searchButtonDisabled,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const { values, setValues } = useForm(searchValues);
  const [unitType, setUnitType] = useState(
    encryptStorage.getItem("unitTypeName")
  );
  const [showLastThreeLfc, setShowLastThreeLfc] = useState(false);
  const [showAllUnits, setShowAllUnits] = useState("primary");
  const [showAllZones, setShowAllZones] = useState("white");
  const [showAllHalqas, setShowAllHalqas] = useState("white");
  const [showAllTanzeem, setShowAllTanzeem] = useState("white");
  const [showAllUsra, setShowAllUsra] = useState("white");
  const [date, setDate] = useState(new Date());
  const [unitList, setUnitList] = useState([]);
  const [lfcList, setLfcList] = useState([]);
  const unitTypeName = "null";

  const rTl = () => {
    if (GetSelectedLanguage() === "ur") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    ApiLFC()
      .getlist()
      .then((res) => {
        setLfcList(res.data);
        setValues({ ...values, lfc: res.data[0] });
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        true,
        date.toISOString(),
        true
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId, date]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          true,
          date.toISOString(),
          true
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        showSelectedUnitsCheck: "None",
      });
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setUnitType(record.label1);
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          true,
          date.toISOString(),
          true
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };

  const handleShowSelectedUnitsCheck = (e) => {
    const { value } = e.target;
    if (value === "0") {
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "None",
      });
    } else if (value === "1") {
      setShowAllZones("primary");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Zone",
      });
    } else if (value === "2") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("primary");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Halqa",
      });
    } else if (value === "3") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("primary");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Tanzeem",
      });
    } else if (value === "4") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("primary");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Usra",
      });
    }
  };

  const handleShowLastThreeLfc = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setShowLastThreeLfc(v);
    setValues({
      ...values,
      showLastThreeLfc: v,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    setSearchValues(values);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="settings_letter_for_counselling" />}
              </Label>
              <Select
                value={values.lfc}
                options={lfcList}
                className="basic-single ur"
                classNamePrefix="select"
                onChange={(e) => {
                  setValues({
                    ...values,
                    lfc: e,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="report_member_select_date" />}
              </Label>
              <DatePicker
                showYearDropdown
                className="form-control"
                selected={values.date}
                onChange={(value) => {
                  setValues({
                    ...values,
                    date: value,
                  });
                  setDate(value);
                }}
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>
          </Col>{" "}
          <Col md="4" style={{ paddingTop: 42 }}>
            <FormGroup check>
              <Label check for="showLastThreeLfc">
                <span
                  style={{
                    float: rTl() ? "right" : "left",
                    marginLeft: rTl() ? "24px" : "0px",
                  }}
                >
                  <Input
                    id="showLastThreeLfc"
                    type="checkbox"
                    name="showLastThreeLfc"
                    checked={showLastThreeLfc}
                    value={!showLastThreeLfc}
                    onChange={handleShowLastThreeLfc}
                  />
                </span>
                <span>
                  {
                    <GetLanguageString props="letter_for_counselling_list_to_send_lfc_show_last_three_lfc" />
                  }
                </span>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {unitList.map((record, index) => {
            const unitOptions = [];
            record.unitList.forEach((d) =>
              unitOptions.push({
                value: d.id,
                label: d.name,
                label1: d.unitTypeName,
              })
            );
            let selected = {};
            let unitName = "";
            if (record.parentUnitName !== null)
              unitName = record.parentUnitName;
            if (record.selectedUnit !== null) {
              selected = {
                value: record.selectedUnit.id,
                label: record.selectedUnit.name,
              };
            }

            return (
              <Col md="2" key={index}>
                <FormGroup>
                  <Label className="col-form-label">
                    {unitName !== "" ? (
                      <GetLanguageString
                        props="member_select_unit_name"
                        value={unitName}
                      />
                    ) : (
                      <GetLanguageString props="member_select_unit" />
                    )}
                  </Label>
                  <Select
                    options={unitOptions}
                    value={selected}
                    className="basic-single ur"
                    classNamePrefix="select"
                    onChange={handleChangeUnits}
                  />
                </FormGroup>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col md="12">
            <ButtonGroup style={{ paddingTop: 22 }} className="text-center">
              {(unitType === "Markaz" ||
                unitType === "Zone" ||
                unitType === "Halqa") && (
                <Button
                  color={showAllUnits}
                  value={0}
                  onClick={handleShowSelectedUnitsCheck}
                >
                  {<GetLanguageString props="report_member_show_all_units" />}
                </Button>
              )}
              {unitType === "Markaz" && (
                <Button
                  color={showAllZones}
                  value={1}
                  onClick={handleShowSelectedUnitsCheck}
                >
                  {<GetLanguageString props="report_member_show_zones" />}
                </Button>
              )}
              {(unitType === "Markaz" || unitType === "Zone") && (
                <Button
                  color={showAllHalqas}
                  value={2}
                  onClick={handleShowSelectedUnitsCheck}
                >
                  {<GetLanguageString props="report_member_show_halqa" />}
                </Button>
              )}
              {(unitType === "Markaz" ||
                unitType === "Zone" ||
                unitType === "Halqa") && (
                <Button
                  color={showAllTanzeem}
                  value={3}
                  onClick={handleShowSelectedUnitsCheck}
                >
                  {<GetLanguageString props="report_member_show_tanzeem" />}
                </Button>
              )}
              {(unitType === "Markaz" ||
                unitType === "Zone" ||
                unitType === "Halqa") && (
                <Button
                  color={showAllUsra}
                  value={4}
                  onClick={handleShowSelectedUnitsCheck}
                >
                  {<GetLanguageString props="report_member_show_usra" />}
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="m-t-md">
          <Col className="text-center">
            <FormGroup>
              <Button
                color="primary"
                disabled={searchButtonDisabled}
                className="m-l-sm"
                type="submit"
              >
                {<GetLanguageString props="common_view_report" />}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SearchListToSendLFC;

import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Row, Table } from "reactstrap";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles, GetPeriodName } from "../../helper/Method";
import { ApiLFC } from "../ApiLFC";
import SearchListToSendLFC from "./SearchListToSendLFC";
import { RiFileExcel2Line } from "react-icons/ri";
import { FcPrint } from "react-icons/fc";
import { saveAs } from "file-saver";

const chunkArray = (arr, size) => {
  return arr.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(arr.slice(i, i + size));
    return acc;
  }, []);
};

const ListToSendLFC = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [searchButton, setSearchButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportTitle, setReportTitle] = useState("");
  const [list, setList] = useState([]);
  const [searchValues, setSearchValues] = useState({
    unitId: loggedInUnitId,
    lfc: {},
    date: new Date(),
    showSelectedUnitsCheck: "None",
    showLastThreeLfc: false,
  });

  useEffect(() => {
    if (searchButton) {
      setSearchButtonDisabled(true);
      setLoading(true);
      ApiLFC()
        .ListToSendLFC(searchValues)
        .then((res) => {
          setList(res.data);
          setSearchButtonDisabled(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSearchButtonDisabled(false);
          setLoading(false);
        });
      setSearchButton(false);
    }
  }, [searchButton, searchValues]);

  const numTables = Math.min(4, list.length); // Max 4 tables
  const rowsPerTable = Math.ceil(list.length / numTables); // Equal rows per table
  const chunkedData = chunkArray(list, rowsPerTable);

  const excelDownload = () => {
    ApiLFC()
      .GenerateListToSendLFCExcel(searchValues)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "LFC List.xlsx");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <div>
      {" "}
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <div className="ibox-content">
            <SearchListToSendLFC
              setSearchButton={setSearchButton}
              setSearchValues={setSearchValues}
              searchValues={searchValues}
              searchButtonDisabled={searchButtonDisabled}
            />
          </div>
        </div>
        {!loading ? (
          list.length > 0 ? (
            <div className="ibox">
              <div className="ibox-title">
                <ButtonGroup className={GetCustomStyles().btn_style}>
                  <Button color="default" size="sm" onClick={excelDownload}>
                    <RiFileExcel2Line
                      size={15}
                      color="#009440"
                      style={{ marginRight: 3, marginBottom: 1 }}
                    />
                    {<GetLanguageString props="member_report_export_excel" />}
                  </Button>
                  <Button color="default" size="sm" onClick={handleReportTitle}>
                    <FcPrint size={15} className={GetCustomStyles().fa_style} />
                    {<GetLanguageString props="common_print" />}
                  </Button>
                </ButtonGroup>
              </div>
              <div className="ibox-content ibox-center" id="section-to-print">
                <Row className="print">
                  <div className="text-center">
                    <h3>Title : {reportTitle}</h3>
                  </div>
                </Row>
                <div className="ibox">
                  <div className="panel panel-info">
                    <div className="panel-heading">
                      <h3 className="text-center">
                        {" "}
                        <GetLanguageString props="counts" />
                      </h3>
                    </div>
                    <div className="panel-body">
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(4, 1fr)", // 4 columns in one row
                          gap: "10px",
                        }}
                      >
                        {chunkedData.map((group, idx) => (
                          <Table key={idx} className="table-bordered m-b-none">
                            <thead>
                              <tr>
                                <th>
                                  <GetLanguageString props="dashboard_event_page_unit_name" />
                                </th>
                                <th className="text-center">
                                  <GetLanguageString props="count" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {group.map((record, index) => (
                                <tr key={index}>
                                  <td
                                    className={
                                      record.unitName.includes("total")
                                        ? ""
                                        : "ur"
                                    }
                                    style={{
                                      fontWeight: record.unitName.includes(
                                        "total"
                                      )
                                        ? "bold"
                                        : "",
                                    }}
                                  >
                                    {record.unitName.includes("total") ? (
                                      <GetLanguageString props="quick_reports5_total" />
                                    ) : (
                                      record.unitName
                                    )}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      fontWeight: record.unitName.includes(
                                        "total"
                                      )
                                        ? "bold"
                                        : "",
                                    }}
                                  >
                                    {record.count}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ))}
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  {list.map((record, idx) =>
                    record.unitName.includes("total") ? null : (
                      <div className="panel panel-info">
                        <div className="panel-heading">
                          <h2 className="text-center ur">{record.unitName} </h2>
                        </div>
                        <div className="panel-body">
                          {record.list.length > 0 ? (
                            <Table
                              key={idx}
                              className="table-bordered m-b-none"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>
                                    <GetLanguageString props="member_first_name" />
                                  </th>
                                  <th>
                                    <GetLanguageString props="member_reg_number" />
                                  </th>
                                  <th>
                                    <GetLanguageString props="settings_responsibilities" />
                                  </th>
                                  <th>
                                    <GetLanguageString props="unit" />
                                  </th>
                                  {searchValues.showLastThreeLfc ? (
                                    <th colSpan={"3"} className="text-center">
                                      <GetLanguageString props="letter_for_counselling_list_to_send_lfc_last_three_lfc" />
                                    </th>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                {record.list.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="ur">{item.name}</td>
                                    <td>{item.regNo}</td>
                                    <td className="ur">
                                      {item.responsibilities}
                                    </td>
                                    <td className="ur">{item.unitName}</td>

                                    {searchValues.showLastThreeLfc ? (
                                      <>
                                        <td className="ur">
                                          {item.letterFrequency1 !== null
                                            ? GetPeriodName(
                                                item.letterFrequency1,
                                                item.letterForCounsellingFrequency1
                                              ) +
                                              "(" +
                                              item.letterForCounsellingYear1 +
                                              ")"
                                            : "-"}
                                        </td>
                                        <td className="ur">
                                          {item.letterFrequency2 !== null
                                            ? GetPeriodName(
                                                item.letterFrequency2,
                                                item.letterForCounsellingFrequency2
                                              ) +
                                              "(" +
                                              item.letterForCounsellingYear2 +
                                              ")"
                                            : "-"}
                                        </td>
                                        <td className="ur">
                                          {item.letterFrequency3 !== null
                                            ? GetPeriodName(
                                                item.letterFrequency3,
                                                item.letterForCounsellingFrequency3
                                              ) +
                                              "(" +
                                              item.letterForCounsellingYear3 +
                                              ")"
                                            : "-"}
                                        </td>
                                      </>
                                    ) : null}
                                  </tr>
                                ))}{" "}
                              </tbody>
                            </Table>
                          ) : (
                            <NoResult />
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : null
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default ListToSendLFC;

import React from "react";
import DatePicker from "react-datepicker";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { isUrdu } from "../../../../constants/const";
import { GetLanguageString } from "../../../helper/Components";

const FormStepTasurat = ({ values, setValues }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <>
      <Row>
        <Col md="6">
          <div className="ibox-content">
            <Row>
              <Col md="8">
                <h4>
                  <GetLanguageString
                    props={"member__request_feedback_assign_responsibility"}
                  />
                </h4>
              </Col>
              <Col md="4">
                <FormGroup>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control"
                    selected={new Date(values.dateOfRequest)}
                    dateFormat="dd/MM/yyyy"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={"12"}>
                <FormGroup>
                  <Input
                    type="text"
                    name="muqamiAmeerFeedback"
                    className={isUrdu(values.muqamiAmeerFeedback) ? "ur" : ""}
                    value={values.muqamiAmeerFeedback}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    <GetLanguageString props={"quick_reports3_muqami_ameer"} />
                  </Label>
                  <Input
                    disabled
                    type="text"
                    name="muqamiAmeerName"
                    className={isUrdu(values.muqamiAmeerName) ? "ur" : " "}
                    value={values.muqamiAmeerName}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    <GetLanguageString props={"Tanzeem"} />
                  </Label>
                  <Input
                    disabled
                    type="text"
                    name="muqamiTanzeemName"
                    className={isUrdu(values.muqamiTanzeemName) ? "ur" : " "}
                    value={values.muqamiTanzeemName}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md="6">
          <div className="ibox-content">
            <h4>
              <GetLanguageString
                props={"member__request_validation_assign_responsibility"}
              />
            </h4>
            {/* <Row style={{ marginTop: 28 }}>
              <Col md={"12"}>
                <FormGroup>
                  <Input
                    type="text"
                    name="ameerHalqaFeedback"
                    className={isUrdu(values.ameerHalqaFeedback) ? "ur" : ""}
                    value={values.ameerHalqaFeedback}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row> */}
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label className="col-form-label">
                    <GetLanguageString props={"quick_reports3_ameer_halqa"} />
                  </Label>
                  <Input
                    disabled
                    type="text"
                    name="ameerHalqaName"
                    className={isUrdu(values.ameerHalqaName) ? "ur" : ""}
                    value={values.ameerHalqaName}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>{" "}
        </Col>
      </Row>
      <br></br>
      <hr></hr>
      <Row className="m-0">
        <div>
          <h3>Instructions :</h3>
          <ul dir="rtl" style={{ listStyleType: "none" }}>
            <li>
              <h3
                className="ur"
                style={{ fontWeight: "lighter", lineHeight: 1.5 }}
              >
                <span className="font-bold">(i)</span>. جب امیر حلقہ رفیق کی
                فکری و عملی پختگی کے بارے میں اطمینان حاصل کرلے تو وہ متعلقہ
                رفیق کو نقیب بنانے کی توثیق فرما کر یہ فارم مرکز کو بھجوائے گا۔
              </h3>
            </li>
            <li>
              <h3
                className="ur"
                style={{ fontWeight: "lighter", lineHeight: 1.5 }}
              >
                <span className="font-bold">(ii)</span>. مقررہ دائرہ کے نقیب کا
                تقرر کرتے وقت امیر حلقہ یہ جائزہ فارم براہ راست مرکز کو پہنچائیں
                گے
              </h3>
            </li>
            <li>
              <h3
                className="ur"
                style={{ fontWeight: "lighter", lineHeight: 1.5 }}
              >
                <span className="font-bold">(ii)</span>. یہ یاد رہے کہ نقیب قرار
                دینے کا اختیار نظام العمل کے مطابق باقی امراء کو تفویض کیا گیا
                ہے اور اس ضمن میں ان سے امیر حلقہ سے مشاورت و اجازت کا تقاضا ہے
                اور مرکزی اس حوالے سے نگرانی کی ذمہ داری ہے۔ نقیب قرار دینے کے
                عمل میں کسی رخ کو بنیادی پر فیصلے کو تبدیل بھی کیا جا سکتا ہے۔
                لہذا گزارش ہے کہ کسی ریفریز کو نقیب قرار دیتے وقت تجلیت سے پرہیز
                کریں اور متعلقہ ریفریز کے فکر و عمل کے ضمن میں اطمینان کے ساتھ
                ساتھ تنظیمی ضابطوں کا خصوصی لحاظ رکھیں۔
              </h3>
            </li>
          </ul>
        </div>
      </Row>
    </>
  );
};

export default FormStepTasurat;

import React from "react";
import { Col, Row } from "reactstrap";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { GetLanguageString } from "../../helper/Components";

const LetterForCounsellingMemberDataContainer = ({
  memberValues,
  calledAs,
}) => {
  return (
    <div>
      <Row className="align-items-center d-flex">
        <Col md="1"></Col>
        {/* Name */}
        <Col md="2" className="d-flex">
          <h4 className={GetCustomStyles().btn_style}>
            {<GetLanguageString props="common_name" />} :
          </h4>
          <h3 className="ur ms-2">{memberValues.memberName}</h3>
        </Col>

        {/* Reg Number */}
        <Col md="3" className="d-flex">
          <h4 className={GetCustomStyles().btn_style}>
            {<GetLanguageString props="member_reg_number" />} :
          </h4>
          <h3 className="ms-2">{memberValues.memberRegNo}</h3>
        </Col>

        {/* Responsibility */}
        <Col md="3" className="d-flex">
          <h4 className={GetCustomStyles().btn_style}>
            {<GetLanguageString props="member_responsibility" />} :
          </h4>
          <h3 className="ur ms-2">
            {memberValues.memberResponsibility !== "،" &&
            memberValues.memberResponsibility !== " " ? (
              calledAs === "view" ? (
                <span
                  style={{ fontSize: "15px", fontWeight: "bold" }}
                  className="ur"
                >
                  <ol className="breadcrumb d-inline-block m-0 p-0">
                    {memberValues.memberResponsibility
                      .split("،")
                      ?.map((item, index) => (
                        <li
                          key={index}
                          className={
                            GetSelectedLanguage() === "ur"
                              ? "breadcrumb-item-ur d-inline-block"
                              : "breadcrumb-item d-inline-block"
                          }
                        >
                          <span style={{ color: "black" }}>{item}</span>
                        </li>
                      ))}
                  </ol>
                </span>
              ) : (
                memberValues.memberResponsibility
              )
            ) : (
              "-"
            )}
          </h3>
        </Col>

        {/* Unit */}
        <Col md="3" className="d-flex">
          <h4 className={GetCustomStyles().btn_style}>
            {<GetLanguageString props="unit" />} :
          </h4>
          <h3 className="ur ms-2">
            {memberValues?.memberUnit?.replace(/،/g, " / ")}
          </h3>
        </Col>
      </Row>
    </div>
  );
};

export default LetterForCounsellingMemberDataContainer;

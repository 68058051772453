import { useState, useEffect } from "react";
import moment from "moment";
import { Table, Button, ButtonGroup } from "reactstrap";

import { GetLanguageString, NoResult } from "../helper/Components";
import { addMember } from "../member/ApiMember";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters,
  convertUTCToLocalTime,
} from "../../constants/const";
import { GetSelectedLanguage, GetFooTableCustomStyles } from "../helper/Method";
import AddResponsibilityByMember from "../member/MemberResponsibility/AddResponsibilityByMember";
import DeleteMultipleResponsibility from "../member/MemberResponsibility/DeleteMultipleResponsibility";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  regNo: "neutral",
  memberType: "neutral",
  responsbilities: "neutral",
};

const UnitMembers = ({ unitName, unitId, tab2 }) => {
  const [deleteResponsibilityToggle, setDeleteResponsibilityToggle] =
    useState();
  const [memberLoading, setMemberLoading] = useState(false);
  const [responsibleMembers, setResponsibleMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [addResponsibiltyModal, setAddResponsibiltyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [memberId, setMemberId] = useState(false);
  const [sortObject, setSortObject] = useState(initialValues);

  const handleSorting = (name) => {
    if (sortObject[name] === "neutral" || sortObject[name] === "descending") {
      setSortObject({
        ...initialValues,
        [name]: "ascending",
      });
    } else if (sortObject[name] === "ascending") {
      setSortObject({
        ...initialValues,
        [name]: "descending",
      });
    }
  };

  useEffect(() => {
    if (tab2 === "active") {
      let tempSortObject = {
        isFullNameDescending: sortObject.name === "descending",
        isFullNameAscending: sortObject.name === "ascending",
        isJoiningDateDescending: sortObject.joiningDate === "descending",
        isJoiningDateAscending: sortObject.joiningDate === "ascending",
        isRegNoAscending: sortObject.regNo === "ascending",
        isRegNoDescending: sortObject.regNo === "descending",
        isMemberTypeAscending: sortObject.memberType === "ascending",
        isMemberTypeDescending: sortObject.memberType === "descending",
        isResponsbilitiesAscending: sortObject.responsbilities === "ascending",
        isResponsbilitiesDescending:
          sortObject.responsbilities === "descending",
      };
      setMemberLoading(true);
      addMember()
        .getResponsibleUnitMember(unitId, tempSortObject)
        .then((res) => {
          setResponsibleMembers(res.data);
          setMemberLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setMemberLoading(false);
        });

      addMember()
        .checkCanAddMemberResponsibility(unitId)
        .then((res) => {
          setCanAdd(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [unitId, deleteResponsibilityToggle, tab2, sortObject]);

  const handleDeleteResponsibility = (id) => {
    setMemberId(id);
    setDeleteModal(true);
  };
  return (
    <>
      <>
        {addResponsibiltyModal && (
          <AddResponsibilityByMember
            setAddResponsibiltyModal={setAddResponsibiltyModal}
            unitId={unitId}
            setLoading={setLoading}
            setDeleteResponsibilityToggle={setDeleteResponsibilityToggle}
            deleteResponsibilityToggle={deleteResponsibilityToggle}
          />
        )}
        {deleteModal && (
          <DeleteMultipleResponsibility
            setDeleteModal={setDeleteModal}
            memberId={memberId}
            setDeleteResponsibilityToggle={setDeleteResponsibilityToggle}
            deleteResponsibilityToggle={deleteResponsibilityToggle}
          />
        )}
      </>
      <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
        <div className="panel-body">
          {!memberLoading ? (
            <>
              {((CheckPermission("CanModifyResponsibility") ||
                CheckPermission("CanRequestResponsibilityModification")) &&
                canAdd) ||
              CheckIsAdmin() ? (
                <Button
                  color="primary"
                  className={
                    GetSelectedLanguage() === "en"
                      ? "pull-right m-b-md"
                      : "pull-left m-b-md"
                  }
                  size="sm"
                  onClick={() => {
                    setAddResponsibiltyModal(true);
                  }}
                >
                  {<GetLanguageString props="settings_assign_responsibility" />}
                </Button>
              ) : null}
              {responsibleMembers.length !== 0 ? (
                <>
                  {responsibleMembers?.filter((m) => m.isIncharge).length !==
                  0 ? (
                    <div className="ibox panel panel-primary">
                      <div className="panel-heading">
                        <h5>
                          <GetLanguageString
                            props="unit_InCharge_member"
                            value={unitName}
                          />
                        </h5>
                      </div>
                      <div className="panel-body">
                        {!loading && !memberLoading ? (
                          <div className="table-responsive">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>
                                    {<GetLanguageString props="common_name" />}
                                  </th>
                                  <th>
                                    {
                                      <GetLanguageString props="member_joining_date" />
                                    }
                                  </th>
                                  <th>
                                    {
                                      <GetLanguageString props="member_reg_number" />
                                    }
                                  </th>
                                  <th>
                                    {<GetLanguageString props="member_phone" />}
                                  </th>
                                  <th>
                                    {
                                      <GetLanguageString props="member_address" />
                                    }
                                  </th>
                                  <th>
                                    {
                                      <GetLanguageString props="unit_responsibilities" />
                                    }
                                  </th>
                                  <th>
                                    {
                                      <GetLanguageString props="settings_member_type" />
                                    }
                                  </th>
                                  {CheckPermission("CanModifyResponsibility") ||
                                  CheckPermission(
                                    "CanRequestResponsibilityModification"
                                  ) ||
                                  CheckIsAdmin() ? (
                                    <th className="text-center">
                                      {
                                        <GetLanguageString props="common_action" />
                                      }
                                    </th>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                {responsibleMembers
                                  ?.filter((m) => m.isIncharge)
                                  .map((record, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="ur">{record.name}</td>
                                        <td>
                                          {record.joiningDate !== null
                                            ? moment(
                                                convertUTCToLocalTime(
                                                  record.joiningDate
                                                )
                                              ).format("DD/MM/yyyy")
                                            : ""}
                                        </td>
                                        <td>{record.regNo}</td>
                                        <td>
                                          {record.phone !== null
                                            ? record.phone
                                                .split(",")
                                                .map((phoneNumber, index) => (
                                                  <div key={index}>
                                                    {phoneNumber}
                                                  </div>
                                                ))
                                            : null}
                                        </td>
                                        <td className="ur">
                                          {record.currentAddress}
                                        </td>
                                        <td>
                                          {record.responsbilities.map(
                                            (item, index) => {
                                              return (
                                                <Button
                                                  key={index}
                                                  size="xs"
                                                  color="info"
                                                  className="label label-info m-r-xs"
                                                >
                                                  <h5 className="ur">{item}</h5>
                                                </Button>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td>
                                          {
                                            <Button
                                              size="xs"
                                              color="info"
                                              className="label label-info m-r-xs"
                                            >
                                              <h5 className="ur">
                                                {record.memberTypeName}
                                              </h5>
                                            </Button>
                                          }
                                        </td>
                                        <td className="text-center">
                                          {CheckPermission(
                                            "CanModifyResponsibility"
                                          ) ||
                                          CheckPermission(
                                            "CanRequestResponsibilityModification"
                                          ) ||
                                          (CheckIsAdmin() &&
                                            record.isDeletable) ||
                                          CheckIsAdmin() ? (
                                            <ButtonGroup>
                                              <Button
                                                color="white"
                                                size="sm"
                                                onClick={() => {
                                                  handleDeleteResponsibility(
                                                    record.memberId
                                                  );
                                                }}
                                              >
                                                {
                                                  <GetLanguageString props="unit_member_delete_responsibility" />
                                                }
                                              </Button>
                                            </ButtonGroup>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          LoadingSpinner()
                        )}
                      </div>
                    </div>
                  ) : null}
                  {responsibleMembers?.filter((m) => !m.isIncharge).length !==
                  0 ? (
                    <div className="ibox panel panel-primary">
                      <div className="panel-heading">
                        <h5>
                          <GetLanguageString
                            props="unit_responsible_member"
                            value={unitName}
                          />
                        </h5>
                      </div>
                      <div className="panel-body">
                        {!loading && !memberLoading ? (
                          <div className="table-responsive">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th
                                    className="cursor-pointer"
                                    onClick={() => handleSorting("name")}
                                  >
                                    {<GetLanguageString props="common_name" />}
                                    <span
                                      className={
                                        GetFooTableCustomStyles().span_style
                                      }
                                    >
                                      {sortObject.name === "neutral" ? (
                                        <>
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .up_down_style
                                            }
                                          />
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .down_up_style
                                            }
                                          />
                                        </>
                                      ) : sortObject.name === "ascending" ? (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().up_style
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().down_style
                                          }
                                        />
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    className="cursor-pointer"
                                    onClick={() => handleSorting("joiningDate")}
                                  >
                                    {
                                      <GetLanguageString props="member_joining_date" />
                                    }
                                    <span
                                      className={
                                        GetFooTableCustomStyles().span_style
                                      }
                                    >
                                      {sortObject.joiningDate === "neutral" ? (
                                        <>
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .up_down_style
                                            }
                                          />
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .down_up_style
                                            }
                                          />
                                        </>
                                      ) : sortObject.joiningDate ===
                                        "ascending" ? (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().up_style
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().down_style
                                          }
                                        />
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    className="cursor-pointer"
                                    onClick={() => handleSorting("regNo")}
                                  >
                                    {
                                      <GetLanguageString props="member_reg_number" />
                                    }
                                    <span
                                      className={
                                        GetFooTableCustomStyles().span_style
                                      }
                                    >
                                      {sortObject.regNo === "neutral" ? (
                                        <>
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .up_down_style
                                            }
                                          />
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .down_up_style
                                            }
                                          />
                                        </>
                                      ) : sortObject.regNo === "ascending" ? (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().up_style
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().down_style
                                          }
                                        />
                                      )}
                                    </span>
                                  </th>
                                  <th>
                                    {<GetLanguageString props="member_phone" />}
                                  </th>
                                  <th>
                                    {
                                      <GetLanguageString props="member_address" />
                                    }
                                  </th>
                                  <th
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleSorting("responsbilities")
                                    }
                                  >
                                    {
                                      <GetLanguageString props="unit_responsibilities" />
                                    }
                                    <span
                                      className={
                                        GetFooTableCustomStyles().span_style
                                      }
                                    >
                                      {sortObject.responsbilities ===
                                      "neutral" ? (
                                        <>
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .up_down_style
                                            }
                                          />
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .down_up_style
                                            }
                                          />
                                        </>
                                      ) : sortObject.responsbilities ===
                                        "ascending" ? (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().up_style
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().down_style
                                          }
                                        />
                                      )}
                                    </span>
                                  </th>
                                  <th
                                    className="cursor-pointer"
                                    onClick={() => handleSorting("memberType")}
                                  >
                                    {
                                      <GetLanguageString props="settings_member_type" />
                                    }
                                    <span
                                      className={
                                        GetFooTableCustomStyles().span_style
                                      }
                                    >
                                      {sortObject.memberType === "neutral" ? (
                                        <>
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .up_down_style
                                            }
                                          />
                                          <i
                                            className={
                                              GetFooTableCustomStyles()
                                                .down_up_style
                                            }
                                          />
                                        </>
                                      ) : sortObject.memberType ===
                                        "ascending" ? (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().up_style
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            GetFooTableCustomStyles().down_style
                                          }
                                        />
                                      )}
                                    </span>
                                  </th>
                                  {CheckPermission("CanModifyResponsibility") ||
                                  CheckPermission(
                                    "CanRequestResponsibilityModification"
                                  ) ||
                                  CheckIsAdmin() ? (
                                    <th className="text-center">
                                      {
                                        <GetLanguageString props="common_action" />
                                      }
                                    </th>
                                  ) : null}
                                </tr>
                              </thead>
                              <tbody>
                                {responsibleMembers
                                  ?.filter((m) => !m.isIncharge)
                                  .map((record, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {applyClassToCharacters(
                                            record.name,
                                            "ur"
                                          )}
                                        </td>
                                        <td>
                                          {record.joiningDate !== null
                                            ? moment(
                                                convertUTCToLocalTime(
                                                  record.joiningDate
                                                )
                                              ).format("DD/MM/yyyy")
                                            : ""}
                                        </td>
                                        <td>{record.regNo}</td>
                                        <td>
                                          {record.phone !== null
                                            ? record.phone
                                                .split(",")
                                                .map((phoneNumber, index) => (
                                                  <div key={index}>
                                                    {phoneNumber}
                                                  </div>
                                                ))
                                            : null}
                                        </td>

                                        <td>
                                          {record.currentAddress
                                            ? applyClassToCharacters(
                                                record.currentAddress,
                                                "ur"
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {record.responsbilities.map(
                                            (item, index) => {
                                              return (
                                                <Button
                                                  key={index}
                                                  size="xs"
                                                  color="info"
                                                  className="label label-info m-r-xs"
                                                >
                                                  <h5 className="ur">{item}</h5>
                                                </Button>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {
                                            <Button
                                              size="xs"
                                              color="info"
                                              className="label label-info m-r-xs"
                                            >
                                              <h5 className="ur">
                                                {record.memberTypeName}
                                              </h5>
                                            </Button>
                                          }
                                        </td>
                                        <td className="text-center">
                                          {CheckPermission(
                                            "CanModifyResponsibility"
                                          ) ||
                                          CheckPermission(
                                            "CanRequestResponsibilityModification"
                                          ) ||
                                          (CheckIsAdmin() &&
                                            record.isDeletable) ||
                                          CheckIsAdmin() ? (
                                            <ButtonGroup>
                                              <Button
                                                color="white"
                                                size="sm"
                                                onClick={() => {
                                                  handleDeleteResponsibility(
                                                    record.memberId
                                                  );
                                                }}
                                              >
                                                {
                                                  <GetLanguageString props="unit_member_delete_responsibility" />
                                                }
                                              </Button>
                                            </ButtonGroup>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          LoadingSpinner()
                        )}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <NoResult />
              )}
            </>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default UnitMembers;

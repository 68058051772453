import React, { useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  applyClassToCharacters,
  getStringFromJSX,
  LoadingSpinner,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiAhbab } from "../ahbab/ApiAhbab";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { addMember } from "../member/ApiMember";
import SelectSerialNumber from "../member/SelectSerialNumber";
import { ApiUnitMember } from "../unit/ApiUnit";
import SelectUnitRequestMoveMember from "../unit/SelectUnitRequestMoveMember";

const NotificationAction = ({
  setActionModal,
  notificationName,
  notificationId,
  setRefreshList,
  unitId,
  isMemberMubtadi,
  isNotificationForwarded,
  memberName,
}) => {
  const { t } = useTranslation();
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const responsibilityId = parseInt(encryptStorage.getItem("responsibilityId"));
  const [values, setValues] = useState({
    unit: { value: unitId, label: "" },
  });
  const [defaulValue, setDefaulValue] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [notificationAction, setNotificationAction] = useState({
    code: isNotificationForwarded ? 2 : 1,
    notificationId: notificationId,
    createdById: memberId,
    responsibilityId: responsibilityId,
    comments: "",
    unitId: unitId,
    serialNumber: 0,
  });
  const toggle = () => setActionModal(false);

  const handleApprovalStatus = (e) => {
    const value = e.target.value;
    let code = null;
    if (value === "3") {
      code = 3;
      setDefaulValue(false);
    } else if (value === "1") {
      code = 1;
      setDefaulValue(true);
    } else if (value === "2") {
      code = 2;
      setDefaulValue(false);
    }
    setNotificationAction({
      ...notificationAction,
      code: code,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotificationAction({
      ...notificationAction,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonDisable(true);
    setLoading(true);

    const apiConfig = {
      req_move_member: () =>
        ApiUnitMember().moveMemberDataSave({
          ...notificationAction,
          unitId: values.unit.value,
        }),

      req_move_habib: () =>
        ApiAhbab().MoveHabibDataSave({
          ...notificationAction,
          unitId: values.unit.value,
        }),

      req_add_new_rafiq: () =>
        addMember().AddNewMember({
          ...notificationAction,
          serialNumber,
        }),

      req_assign_responsibility: () =>
        isMemberMubtadi &&
        !notificationName.includes("exception") &&
        notificationAction.code === 1
          ? addMember().MemberResponsbilityRequestForward(notificationAction)
          : addMember().MemberResponsibilityRequestApproval(notificationAction),

      req_exception_assign_responsibility: () =>
        addMember().MemberResponsibilityRequestApproval(notificationAction),
    };

    const handleApiResponse = (res) => {
      const message = res.data;
      const status = message.includes("Reject")
        ? "error"
        : message.includes("Hold")
        ? "warning"
        : "success";

      if (status) {
        swal({
          title: t(message),
          icon: status,
          buttons: "OK",
          className: GetSelectedLanguage() === "en" ? "en" : "ur",
        });
        setRefreshList((prev) => !prev);
      }

      setLoading(false);
      setActionModal(false);
    };

    const apiCall = apiConfig[notificationName];

    if (apiCall) {
      apiCall()
        .then(handleApiResponse)
        .catch((err) => {
          console.error(err);
          swal({
            title: t(err.response?.data) || err,
            icon: "error",
            buttons: "OK",
            className: GetSelectedLanguage() === "en" ? "en" : "ur",
          });
          setLoading(false);
          setActionModal(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size={notificationName.includes("responsibility") ? "lg" : "xl"}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="common_action" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              {!loading ? (
                <>
                  {isMemberMubtadi ? (
                    <h3 className="text-center text-error ur">
                      {notificationName.includes("exception")
                        ? `یہ رفیق (${memberName}) مبتدی ہیں`
                        : !isNotificationForwarded
                        ? `یہ رفیق (${memberName}) مبتدی ہیں، استثناء کی درخواست بھیجیں`
                        : `یہ رفیق (${memberName}) مبتدی ہیں، استثناء کی درخواست گئی ہوئی ہے`}
                    </h3>
                  ) : null}
                  <div className="ibox-content">
                    <Row>
                      <FormGroup>
                        <Label className="m-0">
                          <h4>{<GetLanguageString props="common_action" />}</h4>
                        </Label>
                        <Row>
                          {!isNotificationForwarded ? (
                            <Col md="4" className="text-center">
                              <Label className="col-form-label">
                                <Input
                                  type="radio"
                                  name="code"
                                  className="m-1"
                                  value={1}
                                  checked={defaulValue}
                                  onChange={handleApprovalStatus}
                                />{" "}
                                {isMemberMubtadi &&
                                !notificationName.includes("exception") ? (
                                  <GetLanguageString props="member__request_responsibility_request_for_exception" />
                                ) : (
                                  <GetLanguageString props="notifications_action_approve" />
                                )}
                              </Label>
                            </Col>
                          ) : null}
                          <Col
                            md={!isNotificationForwarded ? "4" : "6"}
                            className="text-center"
                          >
                            {" "}
                            <Label className="col-form-label">
                              <Input
                                type="radio"
                                name="code"
                                className="m-1"
                                value={2}
                                onChange={handleApprovalStatus}
                              />{" "}
                              {
                                <GetLanguageString props="notifications_action_reject" />
                              }
                            </Label>
                          </Col>
                          <Col
                            md={!isNotificationForwarded ? "4" : "6"}
                            className="text-center"
                          >
                            <Label className="col-form-label">
                              <Input
                                type="radio"
                                name="code"
                                className="m-1"
                                value={3}
                                onChange={handleApprovalStatus}
                              />{" "}
                              {<GetLanguageString props="notifications_hold" />}
                            </Label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Label className="col-form-label">
                        <h4>
                          {
                            <GetLanguageString props="member_member_detail_course_comments" />
                          }
                        </h4>
                        <Input
                          type="text"
                          name="comments"
                          className="no-drag"
                          value={getStringFromJSX(
                            applyClassToCharacters(
                              notificationAction.comments,
                              "ur"
                            )
                          )}
                          onChange={handleInputChange}
                        />
                      </Label>
                    </Row>
                  </div>
                  <br></br>

                  {notificationAction.code === 1 ? (
                    notificationName.includes("move") ? (
                      <>
                        <div className="ibox-title">
                          <h4>
                            {
                              <GetLanguageString props="notification_select_unit" />
                            }
                          </h4>
                        </div>
                        <div className="ibox-content">
                          <SelectUnitRequestMoveMember
                            notificationId={notificationId}
                            values={values}
                            setValues={setValues}
                            unitId={unitId}
                            showExcludeUnit={false}
                          />
                        </div>
                      </>
                    ) : notificationName.includes("add_new_rafiq") ? (
                      <div className="ibox-content">
                        <SelectSerialNumber
                          setSerialNumber={setSerialNumber}
                          loading={loading}
                        />
                      </div>
                    ) : null
                  ) : null}
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="primary"
                disabled={buttonDisable}
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default NotificationAction;

import React, { useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import { addMember } from "../member/ApiMember";
import AddLetterForCounselling from "../member/MemberLetterForCounselling/AddLetterForCounselling";

const LetterForCounselling = () => {
  const [serialNumber, setSerialNumber] = useState("");
  const [error, setError] = useState("");
  const [memberId, setMemberId] = useState();
  const [loading, setLoading] = useState(false);
  const [memberValues, setMemberValues] = useState({
    memberName: "",
    memberResponsibility: "",
    memberRegNo: "",
    memberUnit: "",
  });

  const handleSubmit = () => {
    if (serialNumber === "") {
      setError("member_select_serial_number_err");
    } else {
      setLoading(true);
      addMember()
        .GetMemberDetailsBySerialNumber(serialNumber)
        .then((res) => {
          setMemberId(res.data.id);
          setMemberValues({
            memberName: res.data.fullName,
            memberResponsibility: res.data.responsibilityName,
            memberRegNo: res.data.regNo,
            memberUnit: res.data.unitName,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox ">
        <div className="ibox-title">
          <h5>
            {<GetLanguageString props="member_member_letter_for_counselling" />}
          </h5>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="4">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="notifications_see_changes_serial_number" />
                  }
                </Label>
                <Input
                  type="text"
                  name="serialNumber"
                  value={serialNumber}
                  onChange={(e) => {
                    setSerialNumber(e.target.value);
                    setError("");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents form submission or unintended behavior
                      handleSubmit();
                    }
                  }}
                  {...(error && { invalid: true })}
                />
                {error && (
                  <FormFeedback>
                    {" "}
                    {<GetLanguageString props={error} />}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md="1">
              <Button
                style={{ marginTop: 34 }}
                color="primary"
                size={"m"}
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_search" />}
              </Button>
            </Col>
          </Row>
          {!loading && memberId !== undefined ? (
            <AddLetterForCounselling
              values={memberValues}
              rafiqId={memberId}
              setRafiqId={setMemberId}
              setSerialNumber={setSerialNumber}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(LetterForCounselling);

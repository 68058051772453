import moment from "moment";
import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { isUrdu } from "../../../../constants/const";
import GenericSwitch from "../../../shared/GenericSwitch";

const FormStepTanzeem = ({ setValues, values }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <>
      <Row>
        {" "}
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ملتزم نصاب مکمل کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMultazimNisaabComplete"
                  value={values.isMultazimNisaabComplete}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  ذمہ داران نصاب مکمل کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isZimedaranNisaabComplete"
                  value={values.isZimedaranNisaabComplete}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  مدرسین نصاب مکمل کر لیا ہے؟
                </Label>
              </Col>

              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isMudariseenNisaabComplete"
                  value={values.isMudariseenNisaabComplete}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Row>
              <Col md="10">
                <Label className="col-form-label ur" style={{ fontSize: 15 }}>
                  نظام العمل اور راہنمااصول کا مطالعہ کر لیا ہے؟
                </Label>
              </Col>
              <Col md="2" style={{ marginTop: 5 }}>
                <GenericSwitch
                  label="isReadNizamUlAmalAndRahnumaAsool"
                  value={values.isReadNizamUlAmalAndRahnumaAsool}
                  setValues={setValues}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur">عربی ٹیسٹ</Label>

            <Input
              type="text"
              className="ur"
              name="arabicGrammarTestStatus"
              disabled
              value={
                values.arabicGrammarTestStatus
                  ? values.arabicGrammarTestStatus
                  : "نہیں دیا"
              }
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label ur">تجوید ٹیسٹ</Label>
            <Input
              type="text"
              className="ur"
              name="tajweebTestStatus"
              disabled
              value={
                values.tajweebTestStatus ? values.tajweedTestStatus : "نہیں دیا"
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              شمولیت
            </Label>
            <Input
              type="text"
              name="joiningDate"
              disabled
              value={
                values.joiningDate
                  ? moment(values.joiningDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ملتزم قرار پائے
            </Label>
            <Input
              type="text"
              name="multazimDate"
              disabled
              value={
                values.multazimDate
                  ? moment(values.multazimDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              ملتزم تربیتی کورس
            </Label>
            <Input
              type="text"
              name="multazimCourseDate"
              disabled
              value={
                values.multazimCourseDate
                  ? moment(values.multazimCourseDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              نقباء کورس
            </Label>
            <Input
              type="text"
              name="nuqabaCourseDate"
              disabled
              value={
                values.nuqabaCourseDate
                  ? moment(values.nuqabaCourseDate).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              مدرسین کورس
            </Label>
            <Input
              type="text"
              name="mudariseenCourse"
              disabled
              value={
                values.mudariseenCourse
                  ? moment(values.mudariseenCourse).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              مدرسین ریفریشر کورس
            </Label>
            <Input
              type="text"
              name="mudariseenRefresherCourse"
              disabled
              value={
                values.mudariseenRefresherCourse
                  ? moment(values.mudariseenRefresherCourse).format(
                      "DD/MM/yyyy"
                    )
                  : "-"
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              امراءو نقباء تربیتی و مشاورتی اجتماع
            </Label>
            <Input
              type="text"
              name="umraNuqabaCourse"
              disabled
              value={
                values.umraNuqabaCourse
                  ? moment(values.umraNuqabaCourse).format("DD/MM/yyyy")
                  : "-"
              }
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              دینی تعلیم
            </Label>
            <Input
              type="text"
              name="deeniEducation"
              className={isUrdu(values.deeniEducation) ? "ur" : " "}
              value={values.deeniEducation}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label ur" style={{ fontSize: 15 }}>
              دنیوی تعلیم
            </Label>
            <Input
              type="text"
              name="duniyawiEducation"
              className={isUrdu(values.duniyawiEducation) ? "ur" : " "}
              value={values.duniyawiEducation}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default FormStepTanzeem;

import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiLFC() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  const url = BASEURL + "letterforcounselling/";
  return {
    getlist: () => authAxios.get(url + "GetLFCList/"),
    ListToSendLFC: (values) => authAxios.post(url + "GetListToSendLFC/", values),
    GenerateListToSendLFCExcel: (values) =>
      fileAuthAxios.post(url + "GenerateListToSendLFCExcel/", values),
  };
}

import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import MemberResponsibilityList from "../MemberResponsibility/MemberResponsibilityList";

const UpdateMemberResponsibility = ({
  history,
  tab8,
  memberId,
  unitId,
  unitName,
  addNewRafiq,
  setUpdatedDetails,
  activeTab,
  setDiscard,
  discard,
  memberTypeId
}) => {

  useEffect(() => {
    // Check if the active tab has changed
    if(tab8 === "active"){
    if (activeTab && activeTab !== "tab8") {
      setDiscard(!discard);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <div role="tabpanel" id="tab-8" className={"tab-pane " + tab8}>
      <MemberResponsibilityList
        tab8={tab8}
        memberId={memberId}
        unitId={unitId}
        unitName={unitName}
        addNewRafiq={addNewRafiq}
        setUpdatedDetails={setUpdatedDetails}
        memberTypeId={memberTypeId}
      />
    </div>
  );
};

export default withRouter(UpdateMemberResponsibility);

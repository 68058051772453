import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { FaListUl } from "react-icons/fa";
import { GetLanguageString } from "../../helper/Components";

const QuickLFCReports = ({ history }) => {
  return (
    <div className="wrapper wrapper-content wrapper-custom">
      <div className="ibox">
        <div className="ibox-content panel-body">
          <h3>{<GetLanguageString props="quick_reports_heading" />}</h3>
          <p>{<GetLanguageString props="quick_reports_message" />}</p>
          <Row>
            <Col xl="1" className="text-center">
              <Button
                className="btn-danger dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/letterForCounsellingReports/listToSendLFC`,
                  })
                }
              >
                <FaListUl />
              </Button>
              <h4>
                {
                  <GetLanguageString props="letter_for_counselling_list_to_send_lfc_quick_report_title" />
                }
              </h4>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withRouter(QuickLFCReports);

import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { GetLanguageString } from "../../../helper/Components";
import useForm from "../../../shared/useForm";
import { ApiUnit } from "../../../unit/ApiUnit";

const SearchAhbabFromContactUsFormAnalyticsReport = ({
  setSearchButton,
  setSearchValues,
  searchValues,
  searchButtonDisabled,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitType, setUnitType] = useState(
    encryptStorage.getItem("unitTypeName")
  );
  const [showAllUnits, setShowAllUnits] = useState("primary");
  const [showAllZones, setShowAllZones] = useState("white");
  const [showAllHalqas, setShowAllHalqas] = useState("white");
  const [showAllTanzeem, setShowAllTanzeem] = useState("white");
  const [showAllUsra, setShowAllUsra] = useState("white");
  const unitTypeName = "null";

  const [unitList, setUnitList] = useState([]);
  const { values, setValues } = useForm(searchValues);

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        true,
        new Date().toISOString(),
        true
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          true,
          new Date().toISOString(),
          true
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        showSelectedUnitsCheck: "None",
      });
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setUnitType(record.label1);
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          true,
          new Date().toISOString(),
          true
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    setSearchValues(values);
  };
  const handleShowSelectedUnitsCheck = (e) => {
    const { value } = e.target;
    if (value === "0") {
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "None",
      });
    } else if (value === "1") {
      setShowAllZones("primary");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Zone",
      });
    } else if (value === "2") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("primary");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Halqa",
      });
    } else if (value === "3") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("primary");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Tanzeem",
      });
    } else if (value === "4") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("primary");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Usra",
      });
    }
  };
  return (
    <div className="ibox noprint">
      <div className="ibox-title">
        <h5>{<GetLanguageString props="common_generate_report" />}</h5>
      </div>
      <div className="ibox-content">
        <Form onSubmit={handleSubmit}>
          <Row>
            {unitList.map((record, index) => {
              const unitOptions = [];
              record.unitList.forEach((d) =>
                unitOptions.push({
                  value: d.id,
                  label: d.name,
                  label1: d.unitTypeName,
                })
              );
              let selected = {};
              let unitName = "";
              if (record.parentUnitName !== null)
                unitName = record.parentUnitName;
              if (record.selectedUnit !== null) {
                selected = {
                  value: record.selectedUnit.id,
                  label: record.selectedUnit.name,
                };
              }

              return (
                <Col md="2" key={index}>
                  <FormGroup>
                    <Label className="col-form-label">
                      {unitName !== "" ? (
                        <GetLanguageString
                          props="member_select_unit_name"
                          value={unitName}
                        />
                      ) : (
                        <GetLanguageString props="member_select_unit" />
                      )}
                    </Label>
                    <Select
                      options={unitOptions}
                      value={selected}
                      className="basic-single ur"
                      classNamePrefix="select"
                      onChange={handleChangeUnits}
                    />
                  </FormGroup>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col md="12">
              <ButtonGroup style={{ paddingTop: 22 }} className="text-center">
                {(unitType === "Markaz" ||
                  unitType === "Zone" ||
                  unitType === "Halqa") && (
                  <Button
                    color={showAllUnits}
                    value={0}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_all_units" />}
                  </Button>
                )}
                {unitType === "Markaz" && (
                  <Button
                    color={showAllZones}
                    value={1}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_zones" />}
                  </Button>
                )}
                {(unitType === "Markaz" || unitType === "Zone") && (
                  <Button
                    color={showAllHalqas}
                    value={2}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_halqa" />}
                  </Button>
                )}
                {(unitType === "Markaz" ||
                  unitType === "Zone" ||
                  unitType === "Halqa") && (
                  <Button
                    color={showAllTanzeem}
                    value={3}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_tanzeem" />}
                  </Button>
                )}
                {(unitType === "Markaz" ||
                  unitType === "Zone" ||
                  unitType === "Halqa") && (
                  <Button
                    color={showAllUsra}
                    value={4}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_usra" />}
                  </Button>
                )}
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="m-t-md">
            <Col className="text-center">
              <FormGroup>
                <Button
                  color="primary"
                  disabled={searchButtonDisabled}
                  className="m-l-sm"
                  type="submit"
                >
                  {<GetLanguageString props="common_view_report" />}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default SearchAhbabFromContactUsFormAnalyticsReport;

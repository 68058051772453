import React, { useEffect, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { Button, ButtonGroup, Col, NavLink, Row } from "reactstrap";
import { GetSelectedLanguage } from "../../helper/Method";

import moment from "moment";
import { ToastContainer } from "react-toastify";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import SelectAssociatedMember from "../../ahbab/ContactFormAhbab/SelectAssociatedMember";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import ChangePageTitle from "../../shared/ChangePageTitle";
import { ApiUser } from "../../users/ApiUsers";
import { addMember } from "../ApiMember";
import ManagePermissions from "../ManagePermissions";
import MarkRafiqToEx from "../MarkRafiqToEx";
import MoveMemberModal from "../MoveMemberModal";
import PrintMember from "../PrintMember";
import MemberAccessRights from "./MemberAccessRights";
import MemberAddressDetails from "./MemberAddressDetaills";
import MemberBooks from "./MemberBooks";
import MemberContactDetails from "./MemberContactDetails";
import MemberCourses from "./MemberCourses";
import MemberDocuments from "./MemberDocuments";
import MemberEventDetails from "./MemberEventDetails";
import MemberFunds from "./MemberFunds";
import MemberInfo from "./MemberInfo";
import MemberLetterForCounselling from "./MemberLetterForCounselling";
import MemberMudarisInfo from "./MemberMudarisInfo";
import MemberPledges from "./MemberPledges";
import MemberResponsibility from "./MemberResponsibility";
import MemberTypeHistory from "./MemberTypeHistory";
import MemberUnit from "./MemberUnit";

const MembersDetails = ({ history, match }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [accessRightsLoading, setAccessRightsLoading] = useState(false);
  const [docsNames, setDocsNames] = useState();
  const unitId = location.state?.unitId;
  const isEditable = location.state?.isEditable;
  const canModifyType = location.state?.canModifyType;
  const memberTypeId = location.state?.memberTypeId;
  const calledAs = location.state?.calledAs;
  const isAssociated = location.state?.isAssociated;
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [permissionModal, setPermissionModal] = useState(false);
  const [markRafiqEx, setMarkRafiqExModal] = useState(false);
  const [isRafiqEx, setIsRafiqEx] = useState(false);
  const [editValue, setEditValue] = useState(0);
  const [currentMemberId, setCurrentMemberId] = useState(0);
  const [currentRecordRegNo, setCurrentRecordRegNo] = useState("");
  const { id } = useParams();
  const [memberValues, setMemberValues] = useState({
    birthDate: null,
    cnic: "",
    currentAddress: "",
    permanentAddress: "",
    country: "",
    city: "",
    fatherName: "",
    fullName: "",
    serialNumber: "",
    whatsAppNo: "",
    phone: "",
    email: "",
    education: "",
    educationField: "",
    regNo: "",
    joiningDate: null,
    unitName: "",
    memberType: "",
    responsibility: "",
    maritalStatus: "",
    bloodGroup: "",
    countryDate: "",
    daughters: "",
    sons: "",
    occupation: "",
    age: "",
    colorCode: "",
    unitId: loggedInUnitId,
    responsibilityName: "",
    previousRecordId: null,
    previousRecordRegNo: "",
    currentRecordId: null,
    currentRecordRegNo: "",
    currentUnitAndResponsibility: {},
    memberResponsibilityList: [],
    memberTypeHistoryList: [],
    memberUnitList: [],
    letterForCounsellingList: [],
    memberContactDetails: [],
  });
  useEffect(() => {
    if (currentMemberId === editValue) {
      setCurrentRecordRegNo(memberValues.regNo);
    }
  }, [currentMemberId, editValue, memberValues]);

  useEffect(() => {
    if (location.pathname === "/personalInfo") {
      setEditValue(memberId);
      setCurrentMemberId(memberId);
    } else {
      setEditValue(parseInt(id));
      setCurrentMemberId(parseInt(id));
    }
  }, [memberId, id, location]);

  // Creating local storage variebles to handle the change of Active Tab
  const selectedLanguage = GetSelectedLanguage();
  const localTab1 = encryptStorage.getItem("memberdetailstab1");
  const localTab2 = encryptStorage.getItem("memberdetailstab2");
  const localTab3 = encryptStorage.getItem("memberdetailstab3");
  const localTab4 = encryptStorage.getItem("memberdetailstab4");
  const localTab5 = encryptStorage.getItem("memberdetailstab5");
  const localTab6 = encryptStorage.getItem("memberdetailstab6");
  const localTab7 = encryptStorage.getItem("memberdetailstab7");
  const localTab8 = encryptStorage.getItem("memberdetailstab8");
  const localTab9 = encryptStorage.getItem("memberdetailstab9");
  const localTab10 = encryptStorage.getItem("memberdetailstab10");
  const localTab11 = encryptStorage.getItem("memberdetailstab11");
  const localTab12 = encryptStorage.getItem("memberdetailstab12");
  const localTab13 = encryptStorage.getItem("memberdetailstab13");
  const localTab14 = encryptStorage.getItem("memberdetailstab14");
  const localTab15 = encryptStorage.getItem("memberdetailstab15");

  // use states to mange the change in Active status of Tabs
  const [tab1, setTab1] = useState(
    localTab1 !== undefined ? localTab1 : "active"
  );
  const [tab2, setTab2] = useState(localTab2 !== undefined ? localTab2 : "");
  const [tab3, setTab3] = useState(localTab3 !== undefined ? localTab3 : "");
  const [tab4, setTab4] = useState(localTab4 !== undefined ? localTab4 : "");
  const [tab5, setTab5] = useState(localTab5 !== undefined ? localTab5 : "");
  const [tab6, setTab6] = useState(localTab6 !== undefined ? localTab6 : "");
  const [tab7, setTab7] = useState(localTab7 !== undefined ? localTab7 : "");
  const [tab8, setTab8] = useState(localTab8 !== undefined ? localTab8 : "");
  const [tab9, setTab9] = useState(localTab9 !== undefined ? localTab9 : "");
  const [tab10, setTab10] = useState(
    localTab10 !== undefined ? localTab10 : ""
  );
  const [tab11, setTab11] = useState(
    localTab11 !== undefined ? localTab11 : ""
  );
  const [tab12, setTab12] = useState(
    localTab12 !== undefined ? localTab12 : ""
  );
  const [tab13, setTab13] = useState(
    localTab13 !== undefined ? localTab13 : ""
  );
  const [tab14, setTab14] = useState(
    localTab14 !== undefined ? localTab14 : ""
  );
  const [tab15, setTab15] = useState(
    localTab15 !== undefined ? localTab15 : ""
  );
  const [courseList, setCourseList] = useState();
  const [check, setCheck] = useState();
  const [moveMemberModal, setMoveMemberModal] = useState(false);
  const [books, setBooks] = useState();
  const [pledges, setPledges] = useState();
  const [assessments, setAssessments] = useState();
  const [accessRights, setAccessRights] = useState();
  const [refresh, setRefresh] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [showAssociatedMemberModal, setShowAssociatedMemberModal] =
    useState(false);

  // Make the Tab status Active and other's to empty
  const handleActiveTab = () => {
    encryptStorage.setItem("memberdetailstab1", tab1);
    encryptStorage.setItem("memberdetailstab2", tab2);
    encryptStorage.setItem("memberdetailstab3", tab3);
    encryptStorage.setItem("memberdetailstab4", tab4);
    encryptStorage.setItem("memberdetailstab5", tab5);
    encryptStorage.setItem("memberdetailstab6", tab6);
    encryptStorage.setItem("memberdetailstab7", tab7);
    encryptStorage.setItem("memberdetailstab8", tab8);
    encryptStorage.setItem("memberdetailstab9", tab9);
    encryptStorage.setItem("memberdetailstab10", tab10);
    encryptStorage.setItem("memberdetailstab11", tab11);
    encryptStorage.setItem("memberdetailstab12", tab12);
    encryptStorage.setItem("memberdetailstab13", tab13);
    encryptStorage.setItem("memberdetailstab14", tab14);
    encryptStorage.setItem("memberdetailstab15", tab15);
  };
  useEffect(() => {
    handleActiveTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
    tab14,
    tab15,
  ]);

  useEffect(() => {
    if (tab8 === "active") {
      handleMemberAccessRights();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab8, editValue]);

  const handleReport = () => {
    window.print();
  };

  const handleMarkExRafiq = () => {
    setMarkRafiqExModal(true);
  };

  const handleMemberAccessRights = () => {
    let value = editValue;
    setAccessRightsLoading(true);
    ApiUser()
      .GetMemberAccessRights(value)
      .then((res) => {
        setAccessRights(res.data);
        setAccessRightsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAccessRightsLoading(false);
      });
  };

  useEffect(() => {
    if (editValue !== 0) {
      setImageLoading(true);
      addMember()
        .getMemberPhoto(editValue)
        .then((res) => {
          if (res.data) {
            setImageUrl("data:image/jpeg;base64," + res.data.image);
          }
          setImageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setImageLoading(false);
        });
    }
  }, [editValue]);

  useEffect(() => {
    if (editValue !== 0) {
      setLoading(true);
      addMember()
        .getMemberById(editValue)
        .then((res) => {
          setMemberValues({
            ...res.data,
            birthDate: res.data.birthDate
              ? new Date(convertUTCToLocalTime(res.data.birthDate))
              : null,
            joiningDate: res.data.joiningDate
              ? new Date(convertUTCToLocalTime(res.data.joiningDate))
              : null,
            age:
              new Date() -
              new Date(convertUTCToLocalTime(res.data.birthDate)).getTime(),
          });
          setCourseList(
            Object.entries(
              res.data.memberCourseList.reduce(
                (
                  acc,
                  {
                    startDate,
                    endDate,
                    courseName,
                    courseStatus,
                    comments,
                    shortName,
                    location,
                  }
                ) => {
                  // Group initialization
                  if (!acc[shortName]) {
                    acc[shortName] = [];
                  }
                  acc[shortName].push({
                    courseName,
                    startDate,
                    endDate,
                    courseStatus,
                    comments,
                    location,
                  });
                  return acc;
                },
                {}
              )
            ).map(([label, details]) => ({ label, details }))
          );
          setBooks(res.data.membersBooksList);
          setPledges(res.data.memberPledgeList);
          setAssessments(res.data.memberAssessmentList);
          setDocsNames(res.data.memberDocsNames);
          setIsRafiqEx(res.data.isExRafiq);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    //  eslint-disable-next-line
  }, [editValue, refresh]);

  const handleOnMoveMember = (e) => {
    const { name } = e.target;
    setCheck(name);
    setMoveMemberModal(true);
  };
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <ChangePageTitle
        pageTitle={`TI-ERP | ${
          memberValues.serialNumber !== null && memberTypeId !== 11
            ? memberValues.serialNumber
            : "حبیب"
        } | ${memberValues.fullName}`}
      />

      <ToastContainer
        autoClose={3000}
        closeOnClick
        bodyClassName={selectedLanguage}
        pauseOnHover={false}
        draggable
      />

      <PrintMember
        memberValues={memberValues}
        pledges={pledges}
        courseList={courseList}
        assessments={assessments}
        books={books}
        docsNames={docsNames}
        croppedImage={imageUrl}
        contactList={memberValues.memberContactDetails}
        mudarisInfo={memberValues.memberMudarisInfo}
      />

      {moveMemberModal && (
        <MoveMemberModal
          memberId={editValue}
          unitId={memberValues.unitId}
          check={check}
          setMoveMemberModal={setMoveMemberModal}
          setRefresh={setRefresh}
          refresh={refresh}
          calledAs={
            memberTypeId === 11
              ? calledAs
                ? calledAs === "localHabib"
                  ? "habib"
                  : "tanzeemOrgHabib"
                : "rafiq"
              : "rafiq"
          }
        />
      )}

      {showAssociatedMemberModal && (
        <SelectAssociatedMember
          memberHabibId={editValue}
          habibUnitId={unitId}
          setShowAssociatedMemberModal={setShowAssociatedMemberModal}
          setRefreshList={setRefresh}
          refreshList={refresh}
        />
      )}
      {permissionModal ? (
        <ManagePermissions
          memberId={editValue}
          setPermissionModal={setPermissionModal}
          handleMemberAccessRights={handleMemberAccessRights}
        />
      ) : null}

      {markRafiqEx ? (
        <MarkRafiqToEx
          setMarkRafiqExModal={setMarkRafiqExModal}
          memberId={editValue}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}

      {!loading ? (
        <>
          {Object.keys(memberValues).length !== 0 ? (
            <>
              {/* First Row with buttons to perform some operations on the Rafiq */}
              <Row className={GetCustomStyles().btn_style}>
                <ButtonGroup>
                  <>
                    {(calledAs === "localHabib"
                      ? CheckPermission("CanEditAhbab")
                      : CheckPermission("CanEditTanzeemOrgAhbab")) &&
                    memberTypeId === 11 ? (
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          setShowAssociatedMemberModal(true);
                        }}
                      >
                        {isAssociated ? (
                          <GetLanguageString props="ahbab_update_associated_habib" />
                        ) : (
                          <GetLanguageString props="ahbab_associate_with_habib" />
                        )}
                      </Button>
                    ) : null}
                    {location.pathname !== "/personalInfo" &&
                    CheckPermission("Ex") &&
                    !isRafiqEx &&
                    memberTypeId !== 11 ? (
                      <Button
                        color="danger"
                        size="sm"
                        onClick={handleMarkExRafiq}
                      >
                        {
                          <GetLanguageString props="member_details_button_mark_rafiq_ex" />
                        }
                      </Button>
                    ) : null}
                    {isEditable ? (
                      <Button
                        color="info"
                        size="sm"
                        onClick={() =>
                          history.push({
                            pathname: `/member/updateMember/${id}`,
                            state: {
                              unitId: loggedInUnitId,
                              currentUnitId: unitId,
                              unitName: "",
                              canModifyType: canModifyType,
                              addNewRafiq: false,
                              memberTypeId: memberTypeId,
                              calledAs: "rafiq",
                              isAssociated:
                                memberTypeId === 11 ? isAssociated : false,
                            },
                          })
                        }
                      >
                        {
                          <GetLanguageString
                            props={
                              memberTypeId !== 11 ? "edit_member" : "edit_habib"
                            }
                          />
                        }
                      </Button>
                    ) : null}{" "}
                    <div>
                      {!isRafiqEx ? (
                        <ButtonGroup>
                          {(location.pathname !== "/personalInfo" &&
                            CheckIsAdmin()) ||
                          unitTypeName === "Markaz" ||
                          unitTypeName === "Zone" ||
                          unitTypeName === "ZoneOS" ? (
                            <Button
                              color="success"
                              size="sm"
                              onClick={(e) => handleOnMoveMember(e)}
                              name="admin"
                              value={unitId}
                            >
                              {
                                <GetLanguageString props="unit_unit_member_move_member" />
                              }
                            </Button>
                          ) : null}
                          {location.pathname !== "/personalInfo" &&
                          !CheckIsAdmin() &&
                          (CheckPermission("CanMoveMembersLocalTransfer") ||
                            CheckPermission("CanMoveTanzeemOrgAhbab")) &&
                          unitTypeName !== "Markaz" &&
                          unitTypeName !== "Zone" &&
                          unitTypeName !== "ZoneOS" &&
                          !isRafiqEx ? (
                            <Button
                              color="success"
                              size="sm"
                              onClick={(e) => handleOnMoveMember(e)}
                              name="Local"
                              value={unitId}
                            >
                              <GetLanguageString props="unit_unitmember_move_member_localy" />
                            </Button>
                          ) : null}
                          {location.pathname !== "/personalInfo" &&
                          !CheckIsAdmin() &&
                          (CheckPermission("CanMoveMembersOutsideTransfer") ||
                            CheckPermission(
                              "CanRequestMoveMembersOutsideTransfer"
                            ) ||
                            CheckPermission("CanRequestMoveAhbab") ||
                            CheckPermission("CanRequestMoveTanzeemOrgAhbab")) &&
                          unitTypeName !== "Markaz" &&
                          unitTypeName !== "Zone" &&
                          unitTypeName !== "ZoneOS" ? (
                            <Button
                              color="info"
                              size="sm"
                              onClick={(e) => handleOnMoveMember(e)}
                              name="Outside"
                              value={unitId}
                            >
                              <GetLanguageString props="unit_unitmember_move_members_other" />
                            </Button>
                          ) : null}
                        </ButtonGroup>
                      ) : null}
                    </div>
                  </>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => history.goBack()}
                  >
                    {<GetLanguageString props="courses_participants_back" />}
                  </Button>
                  <Button color="primary" size="sm" onClick={handleReport}>
                    {<GetLanguageString props="common_print" />}
                  </Button>
                </ButtonGroup>
              </Row>
              <br></br>
              <br></br>
              <div
                className="ibox-content"
                style={{
                  backgroundColor: "#264058",
                  color: "white",
                  opacity: 0.8,
                  borderBottom: `8px solid ${memberValues.colorCode}`,
                }}
              >
                <Row>
                  {imageLoading ? (
                    <Col md="2" className="responsive-image-col">
                      {LoadingSpinner()}
                    </Col>
                  ) : (
                    imageUrl && (
                      <Col md="2" className="responsive-image-col">
                        <img
                          style={{ height: 180 }}
                          src={imageUrl}
                          className="responsive-image"
                          alt="Uploaded"
                        />
                      </Col>
                    )
                  )}
                  <Col md={imageUrl || imageLoading ? "3" : "4"}>
                    <Row>
                      <h2 style={{ fontSize: "40px" }}>
                        {applyClassToCharacters(memberValues.fullName, "ur")}
                      </h2>
                    </Row>
                    <Row>
                      <h3
                        style={
                          selectedLanguage === "ur"
                            ? { fontSize: "20px", fontWeight: "lighter" }
                            : null
                        }
                      >
                        {<GetLanguageString props="member_fathers_name" />} :{" "}
                        <span
                          style={{ fontSize: "20px", fontWeight: "lighter" }}
                          className="ur"
                        >
                          {applyClassToCharacters(
                            memberValues.fatherName,
                            "ur"
                          )}
                        </span>{" "}
                      </h3>
                    </Row>
                    <Row>
                      <h3
                        style={
                          selectedLanguage === "ur"
                            ? { fontSize: "20px", fontWeight: "lighter" }
                            : null
                        }
                      >
                        {<GetLanguageString props="unit" />} :{" "}
                        <span
                          style={{ fontSize: "20px", fontWeight: "lighter" }}
                          className="ur"
                        >
                          {memberValues?.unitName?.replace(/،/g, " / ")}
                        </span>
                      </h3>
                    </Row>
                  </Col>
                  <Col md={imageUrl || imageLoading ? "3" : "4"}>
                    <Row className="text-center">
                      <h3
                        style={{ fontSize: "30px", unicodeBidi: "plaintext" }}
                      >
                        {memberValues.regNo}
                      </h3>
                    </Row>
                    {memberTypeId !== 11 ? (
                      <Row className="text-center">
                        <h3
                          style={
                            selectedLanguage === "ur"
                              ? { fontSize: "20px", fontWeight: "lighter" }
                              : null
                          }
                        >
                          {
                            <GetLanguageString props="member_registration_date" />
                          }{" "}
                          :{" "}
                          {memberValues.joiningDate
                            ? moment(
                                convertUTCToLocalTime(memberValues.joiningDate)
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </h3>
                      </Row>
                    ) : (
                      <Row className="text-center">
                        <h3
                          style={
                            selectedLanguage === "ur"
                              ? { fontSize: "20px", fontWeight: "lighter" }
                              : null
                          }
                        >
                          {
                            <GetLanguageString props="meeting_members_member_type" />
                          }{" "}
                          :{" "}
                          <span className="ur">{memberValues.memberType}</span>
                        </h3>
                      </Row>
                    )}
                  </Col>
                  <Col md="4">
                    <div className={GetCustomStyles().btn_style}>
                      {memberTypeId !== 11 ? (
                        memberValues.currentUnitAndResponsibility
                          ?.responsibility !== "،" ? (
                          <Row>
                            <h3
                              style={
                                selectedLanguage === "ur"
                                  ? { fontSize: "20px", fontWeight: "lighter" }
                                  : null
                              }
                            >
                              {
                                <GetLanguageString props="member_responsibility" />
                              }{" "}
                              :{" "}
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "lighter",
                                }}
                                className="ur"
                              >
                                <ol
                                  className="breadcrumb"
                                  style={{
                                    backgroundColor: "#264058",
                                  }}
                                >
                                  {memberValues.responsibilityName
                                    .split("،")
                                    ?.map((item, index) => {
                                      return (
                                        <li
                                          style={{
                                            backgroundColor: "#264058",
                                          }}
                                          className={
                                            GetSelectedLanguage() === "ur"
                                              ? "breadcrumb-item-ur"
                                              : "breadcrumb-item"
                                          }
                                          key={index}
                                        >
                                          <span style={{ color: "white" }}>
                                            {item}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ol>
                              </span>
                            </h3>
                          </Row>
                        ) : (
                          "-"
                        )
                      ) : null}
                      <Row>
                        <h3
                          style={
                            selectedLanguage === "ur"
                              ? { fontSize: "20px", fontWeight: "lighter" }
                              : null
                          }
                        >
                          {<GetLanguageString props="member_date_of_birth" />} :{" "}
                          {memberValues.birthDate
                            ? moment(
                                convertUTCToLocalTime(memberValues.birthDate)
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </h3>
                      </Row>{" "}
                      {memberValues.birthDate ? (
                        <Row>
                          <h3
                            style={
                              selectedLanguage === "ur"
                                ? { fontSize: "20px", fontWeight: "lighter" }
                                : null
                            }
                          >
                            {<GetLanguageString props="member_age" />} :{" "}
                            {new Date(memberValues.age).getUTCFullYear() - 1970}
                          </h3>
                        </Row>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <br></br>

              {/* Tabs For the Rafique details*/}
              <Row className="noprint">
                <Col md="12">
                  <div className="tabs-container">
                    <ul className="nav nav-tabs" role="tablist">
                      <li>
                        <NavLink
                          to="#"
                          className={"nav-link " + tab1}
                          onClick={() => {
                            setTab1("active");
                            setTab2("");
                            setTab3("");
                            setTab4("");
                            setTab5("");
                            setTab6("");
                            setTab7("");
                            setTab8("");
                            setTab9("");
                            setTab10("");
                            setTab11("");
                            setTab12("");
                            setTab13("");
                            setTab14("");
                            setTab15("");
                            handleActiveTab();
                          }}
                        >
                          <h5>
                            {
                              <GetLanguageString
                                props={
                                  memberTypeId !== 11
                                    ? "member_member_detail"
                                    : "ahbab_transfer_habib_habib_details"
                                }
                              />
                            }
                          </h5>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="#"
                          className={"nav-link " + tab13}
                          onClick={() => {
                            setTab1("");
                            setTab2("");
                            setTab3("");
                            setTab4("");
                            setTab5("");
                            setTab6("");
                            setTab7("");
                            setTab8("");
                            setTab9("");
                            setTab10("");
                            setTab11("");
                            setTab12("");
                            setTab13("active");
                            setTab14("");
                            setTab15("");
                            handleActiveTab();
                          }}
                        >
                          <h5>
                            {
                              <GetLanguageString props="member_address_details" />
                            }
                          </h5>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="#"
                          className={"nav-link " + tab11}
                          onClick={() => {
                            setTab1("");
                            setTab2("");
                            setTab3("");
                            setTab4("");
                            setTab5("");
                            setTab6("");
                            setTab7("");
                            setTab8("");
                            setTab9("");
                            setTab10("");
                            setTab11("active");
                            setTab12("");
                            setTab13("");
                            setTab14("");
                            setTab15("");
                            handleActiveTab();
                          }}
                        >
                          <h5>
                            {
                              <GetLanguageString props="member_contact_details" />
                            }
                          </h5>
                        </NavLink>
                      </li>
                      {memberTypeId !== 11 ? (
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab10}
                            onClick={() => {
                              setTab1("");
                              setTab2("");
                              setTab3("");
                              setTab4("");
                              setTab5("");
                              setTab6("");
                              setTab7("");
                              setTab8("");
                              setTab9("");
                              setTab10("active");
                              setTab11("");
                              setTab12("");
                              setTab13("");
                              setTab14("");
                              setTab15("");
                              handleActiveTab();
                            }}
                          >
                            <h5>
                              <GetLanguageString props="member_member_detail_documents" />
                            </h5>
                          </NavLink>
                        </li>
                      ) : null}
                      <li>
                        <NavLink
                          to="#"
                          className={"nav-link " + tab2}
                          onClick={() => {
                            setTab1("");
                            setTab2("active");
                            setTab3("");
                            setTab4("");
                            setTab5("");
                            setTab6("");
                            setTab7("");
                            setTab8("");
                            setTab9("");
                            setTab10("");
                            setTab11("");
                            setTab12("");
                            setTab13("");
                            setTab14("");
                            setTab15("");
                            handleActiveTab();
                          }}
                        >
                          <h5>
                            <GetLanguageString props="member_member_detail_unit_history" />
                          </h5>
                        </NavLink>
                      </li>
                      {memberTypeId !== 11 ? (
                        <>
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab9}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("");
                                setTab4("");
                                setTab5("");
                                setTab6("");
                                setTab7("");
                                setTab8("");
                                setTab9("active");
                                setTab10("");
                                setTab11("");
                                setTab12("");
                                setTab13("");
                                setTab14("");
                                setTab15("");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                <GetLanguageString props="member_member_detail_responsibility_history" />
                              </h5>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab3}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("active");
                                setTab4("");
                                setTab5("");
                                setTab6("");
                                setTab7("");
                                setTab8("");
                                setTab9("");
                                setTab10("");
                                setTab11("");
                                setTab12("");
                                setTab13("");
                                setTab14("");
                                setTab15("");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                {
                                  <GetLanguageString props="member_member_detail_letter_for_counselling" />
                                }
                              </h5>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab4}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("");
                                setTab4("active");
                                setTab5("");
                                setTab6("");
                                setTab7("");
                                setTab8("");
                                setTab9("");
                                setTab10("");
                                setTab11("");
                                setTab12("");
                                setTab13("");
                                setTab14("");
                                setTab15("");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                <GetLanguageString props="member_member_detail_member_type_history" />
                              </h5>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab12}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("");
                                setTab4("");
                                setTab5("");
                                setTab6("");
                                setTab7("");
                                setTab8("");
                                setTab9("");
                                setTab10("");
                                setTab11("");
                                setTab12("active");
                                setTab13("");
                                setTab14("");
                                setTab15("");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                <GetLanguageString props="member_member_detail_mudaris" />
                              </h5>
                            </NavLink>
                          </li>{" "}
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab15}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("");
                                setTab4("");
                                setTab5("");
                                setTab6("");
                                setTab7("");
                                setTab8("");
                                setTab9("");
                                setTab10("");
                                setTab11("");
                                setTab12("");
                                setTab13("");
                                setTab14("");
                                setTab15("active");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                <GetLanguageString props="sidebar_funds_infaq" />
                              </h5>
                            </NavLink>
                          </li>
                        </>
                      ) : null}

                      <li>
                        <NavLink
                          to="#"
                          className={"nav-link " + tab5}
                          onClick={() => {
                            setTab1("");
                            setTab2("");
                            setTab3("");
                            setTab4("");
                            setTab5("active");
                            setTab6("");
                            setTab7("");
                            setTab8("");
                            setTab9("");
                            setTab10("");
                            setTab11("");
                            setTab12("");
                            setTab13("");
                            setTab14("");
                            setTab15("");
                            handleActiveTab();
                          }}
                        >
                          <h5>
                            <GetLanguageString props="member_member_detail_course" />
                          </h5>
                        </NavLink>
                      </li>
                      {/* Member's related Itma'at Tab */}
                      <li>
                        <NavLink
                          to="#"
                          className={"nav-link " + tab14}
                          onClick={() => {
                            setTab1("");
                            setTab2("");
                            setTab3("");
                            setTab4("");
                            setTab5("");
                            setTab6("");
                            setTab7("");
                            setTab8("");
                            setTab9("");
                            setTab10("");
                            setTab11("");
                            setTab12("");
                            setTab13("");
                            setTab14("active");
                            setTab15("");
                            handleActiveTab();
                          }}
                        >
                          <h5>
                            <GetLanguageString props="member_member's_details_Ijima'at" />
                          </h5>
                        </NavLink>
                      </li>
                      {memberTypeId !== 11 ? (
                        <>
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab6}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("");
                                setTab4("");
                                setTab5("");
                                setTab6("active");
                                setTab7("");
                                setTab8("");
                                setTab9("");
                                setTab10("");
                                setTab11("");
                                setTab12("");
                                setTab13("");
                                setTab14("");
                                setTab15("");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                <GetLanguageString props="member_member_detail_books" />
                              </h5>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="#"
                              className={"nav-link " + tab7}
                              onClick={() => {
                                setTab1("");
                                setTab2("");
                                setTab3("");
                                setTab4("");
                                setTab5("");
                                setTab6("");
                                setTab7("active");
                                setTab8("");
                                setTab10("");
                                setTab11("");
                                setTab12("");
                                setTab13("");
                                setTab14("");
                                setTab15("");
                                handleActiveTab();
                              }}
                            >
                              <h5>
                                <GetLanguageString props="member_member_detail_pledges" />
                              </h5>
                            </NavLink>
                          </li>
                          {!isRafiqEx ? (
                            <li>
                              <NavLink
                                to="#"
                                className={"nav-link " + tab8}
                                onClick={() => {
                                  setTab1("");
                                  setTab2("");
                                  setTab3("");
                                  setTab4("");
                                  setTab5("");
                                  setTab6("");
                                  setTab7("");
                                  setTab8("active");
                                  setTab9("");
                                  setTab10("");
                                  setTab11("");
                                  setTab12("");
                                  setTab13("");
                                  setTab13("");
                                  setTab14("");
                                  setTab15("");
                                  handleMemberAccessRights();
                                  handleActiveTab();
                                }}
                              >
                                <h5>
                                  <GetLanguageString props="member_member_detail_access_rights" />
                                </h5>
                              </NavLink>
                            </li>
                          ) : null}
                        </>
                      ) : null}
                    </ul>
                    <div className="tab-content">
                      <MemberInfo
                        tab1={tab1}
                        memberValues={memberValues}
                        editValue={editValue}
                        setEditValue={setEditValue}
                        id={currentMemberId}
                        currentRecordRegNo={currentRecordRegNo}
                        memberTypeId={memberTypeId}
                      />
                      <MemberAddressDetails
                        tab13={tab13}
                        memberAddressDetails={memberValues.memberAddressDetails}
                      />
                      <MemberContactDetails
                        tab11={tab11}
                        memberContactDetails={memberValues.memberContactDetails}
                      />
                      {memberTypeId !== 11 ? (
                        <MemberDocuments tab10={tab10} editValue={editValue} />
                      ) : null}
                      <MemberUnit
                        tab2={tab2}
                        memberUnitList={memberValues.memberUnitList}
                      />
                      {memberTypeId !== 11 ? (
                        <>
                          <MemberResponsibility
                            tab9={tab9}
                            memberId={editValue}
                          />
                          <MemberLetterForCounselling
                            tab3={tab3}
                            memberId={editValue}
                            letterForCounsellingList={
                              memberValues.letterForCounsellingList
                            }
                            memberValues={{
                              memberName: memberValues.fullName,
                              memberResponsibility: memberValues.responsibilityName,
                              memberRegNo: memberValues.regNo,
                              memberUnit: memberValues.unitName,
                            }}
                          />
                          <MemberTypeHistory
                            tab4={tab4}
                            memberTypeHistoryList={
                              memberValues.memberTypeHistoryList
                            }
                          />
                        </>
                      ) : null}
                      <MemberCourses tab5={tab5} courseList={courseList} />
                      <MemberEventDetails tab14={tab14} editValue={editValue} />
                      {memberTypeId !== 11 ? (
                        <>
                          <MemberBooks tab6={tab6} books={books} />
                          <MemberPledges
                            tab7={tab7}
                            pledges={pledges}
                            editValue={editValue}
                          />
                          {!isRafiqEx ? (
                            <MemberAccessRights
                              tab8={tab8}
                              accessRights={accessRights}
                              editValue={editValue}
                              accessRightsLoading={accessRightsLoading}
                            />
                          ) : null}
                          <MemberMudarisInfo
                            tab12={tab12}
                            editValue={editValue}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                          <MemberFunds tab15={tab15} editValue={editValue} />
                        </>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(MembersDetails);

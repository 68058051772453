import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";
import { Button, ButtonGroup, Row, Table } from "reactstrap";
import { LoadingSpinner } from "../../../../constants/const";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { GetLanguageString, SetReportTitle } from "../../../helper/Components";
import { GetCustomStyles } from "../../../helper/Method";
import { ApiAhbab } from "../../ApiAhbab";
import SearchAhbabFromContactUsFormAnalyticsReport from "./SearchAhbabFromContactUsFormAnalyticsReport";

const AhbabFromContactUsFormAnalyticsReport = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [searchButton, setSearchButton] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportTitle, setReportTitle] = useState("");
  const [data, setData] = useState([]);
  const [searchValues, setSearchValues] = useState({
    unitId: loggedInUnitId,
    showSelectedUnitsCheck: "None",
  });
  let totalCount = {
    total: 0,
    approved: 0,
    rejected: 0,
    cancelled: 0,
    onHold: 0,
    pending: 0,
  };

  const customStyles = {
    border: "2px solid black",
    fontWeight: "bold",
  };

  useEffect(() => {
    if (searchButton) {
      setSearchButtonDisabled(true);
      setLoading(true);
      ApiAhbab()
        .TanzeemOrgAhbabStatusReport(searchValues)
        .then((res) => {
          setData(res.data.result);
          setSearchButtonDisabled(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSearchButtonDisabled(false);
          setLoading(false);
        });
    }
  }, [searchButton, searchValues]);

  const excelDownload = () => {
    ApiAhbab()
      .GenerateTanzeemOrgAhbabStatusReportExcel(searchValues)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "TanzeemOrg Ahbab Statistics Report.xlsx");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <SearchAhbabFromContactUsFormAnalyticsReport
          setSearchButton={setSearchButton}
          setSearchValues={setSearchValues}
          searchValues={searchValues}
          searchButtonDisabled={searchButtonDisabled}
        />
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <h5>
                {<GetLanguageString props="tanzeem_org_statistics_report" />}
              </h5>
              <ButtonGroup className={GetCustomStyles().btn_style}>
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            </div>
            <div className="ibox-content ibox-center" id="section-to-print">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                <>
                  <Table className="table-bordered m-b-none">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_unit_name" />
                          }
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="notifications_approved" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="notifications_cancel" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="notifications_on_hold" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="notifications_pending" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="notifications_reject" />}
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="unit_cummulative_report_total_members" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((record, index) => {
                        totalCount.approved += record.approve;
                        totalCount.rejected += record.reject;
                        totalCount.cancelled += record.cancelled;
                        totalCount.onHold += record.onHold;
                        totalCount.pending += record.pending;
                        totalCount.total += record.grandTotal;
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="ur">{record.unitName}</td>
                            <td className="text-center">{record.approve}</td>
                            <td className="text-center">{record.cancelled}</td>
                            <td className="text-center">{record.onHold}</td>
                            <td className="text-center">{record.pending}</td>
                            <td className="text-center">{record.reject}</td>
                            <td className="text-center">{record.grandTotal}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.approved}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.cancelled}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.onHold}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.pending}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.rejected}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.total}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AhbabFromContactUsFormAnalyticsReport;

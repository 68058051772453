import moment from "moment";
import "moment/locale/ur";
import React from "react";
import { convertUTCToLocalTime } from "../../../../constants/const";
import { personalInfo, section } from "../../../../content/css/customCss";

const PrintMuavaneenForm = ({ printData, responsibilityData }) => {
  const textStyle = { fontSize: "15px" };
  
  const Check = ({ show }) => {
    return (
      <>
        <input
          type="checkbox"
          name="status"
          value="notCompleted"
          checked={show}
          style={{
            marginRight: "5px",
            appearance: "none",
            width: "15px",
            height: "15px",
            textAlign: "center",
            backgroundColor: "white",
            border: "1px solid grey",
            borderRadius: "3px",
            cursor: "pointer",
            display: "inline-block",
            position: "relative",
          }}
        />
        {show && (
          <span
            style={{
              position: "relative",
              left: "13px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            ✔
          </span>
        )}
      </>
    );
  };
  const CheckBox = ({ check, type }) => {
    let show = type === "yes" ? check : !check;

    return (
      <label
        style={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Check show={show} />
        <span style={{ marginRight: "5px" }}>
          {" "}
          {type === "yes" ? "مکمل" : "نا مکمل"}
        </span>
      </label>
    );
  };

  return (
    <div className="ur" style={{ direction: "rtl", marginTop: "-10px" }}>
      <h2 className="text-center">
        <u>جائزہ فارم براۓ تقرر "مقامی و حلقہ جاتی معاون"</u>
      </h2>
      <p
        className="m-3 text-center"
        style={{ ...textStyle, marginRight: 40, marginLeft: 40 }}
      >
        مقامی امیر / امیر حلقہ اپنے معاون کے تقرر کے وقت جائزہ کا اہتمام کرے اور
        اطلاعا یہ فارم نظم بالا کو ارسال کریں
      </p>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "10%",
                fontSize: "15px",
              }}
            >
              نام
            </span>
            <span
              style={{
                display: "inline-block",
                width: "60%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MemberName !== null && printData.MemberName !== ""
                ? printData.MemberName
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "0px",
                display: "inline-block",
                width: "10%",
                fontSize: "15px",
              }}
            >
              ولدیت
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                width: "60%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MemberFatherName !== null &&
              printData.MemberFatherName !== ""
                ? printData.MemberFatherName
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-30px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              رجسٹریشن نمبر
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "60%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MemberRegNo !== null && printData.MemberRegNo !== ""
                ? printData.MemberRegNo
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "8%",
                fontSize: "15px",
              }}
            >
              عمر
            </span>
            <span
              style={{
                display: "inline-block",
                width: "40%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MemberAge !== null && printData.MemberAge !== ""
                ? printData.MemberAge
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-70px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              ازدواجی کیفیت
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                width: "55%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MemberMaritalStatus !== null &&
              printData.MemberMaritalStatus?.Label !== ""
                ? printData.MemberMaritalStatus?.Label
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-80px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              مجوزہ ذمہ داری
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "77%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.ProposedResponsibility !== null &&
              printData.ProposedResponsibility?.Label !== ""
                ? `${printData.ProposedResponsibility?.Label}${
                    responsibilityData.displayName
                      ? ` (${responsibilityData.displayName})`
                      : ""
                  }`
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "40%",
                fontSize: "15px",
              }}
            >
              مجوزہ ذمہ داری پر پہلے کون مقرر تھے؟
            </span>
            <span
              style={{
                display: "inline-block",
                width: "45%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.PreviousAssignedResponsibilityMember !== null &&
              printData.PreviousAssignedResponsibilityMember !== ""
                ? printData.PreviousAssignedResponsibilityMember
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "30px",
                display: "inline-block",
                width: "35%",
                fontSize: "15px",
              }}
            >
              سابقہ ذمہ دار کا رجسٹریشن نمبر
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.PreviousAssignedResponsibilityMemberRegNo !== null &&
              printData.PreviousAssignedResponsibilityMemberRegNo !== ""
                ? printData.PreviousAssignedResponsibilityMemberRegNo
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "60%",
                fontSize: "15px",
              }}
            >
              مجوزہ ذمہ داری کے ساتھ کوئی اور اضافی ذمہ داری بھی ہے؟
            </span>
            <span
              style={{
                display: "inline-block",
                width: "28%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.AdditionalResponsibilities !== null &&
              printData.AdditionalResponsibilities?.length > 0
                ? printData.AdditionalResponsibilities?.join(", ")
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "30px",
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              سابقہ ذمہ داریاں
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "55%",
                borderBottom: "1px dotted black",
                fontSize: "13px",
              }}
            >
              {printData.PreviousResponsibilities !== null &&
              printData.PreviousResponsibilities?.length > 0
                ? printData.PreviousResponsibilities?.join(", ")
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <br></br>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              شمولیت
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "35%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.JoiningDate
                ? moment(printData.JoiningDate).format("DD/MM/yyyy")
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-10px",
                display: "inline-block",
                width: "30%",
                fontSize: "15px",
              }}
            >
              ملتزم قرار پائے
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "35%",
                borderBottom: "1px dotted black",
                fontSize: "14px",
              }}
            >
              {printData.MultazimDate
                ? moment(printData.MultazimDate).format("DD/MM/yyyy")
                : "-"}
            </span>
          </p>{" "}
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-35px",
                display: "inline-block",
                width: "35%",
                fontSize: "15px",
              }}
            >
              ملتزم تربیتی کورس
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "35%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MultazimCourseDate
                ? moment(printData.MultazimCourseDate).format("DD/MM/yyyy")
                : "-"}
            </span>
          </p>{" "}
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-45px",
                display: "inline-block",
                width: "30%",
                fontSize: "15px",
              }}
            >
              ملتزم نصاب؟
            </span>
            <div
              style={{
                marginRight: "15px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60%",
                fontSize: "15px",
                padding: "5px",
              }}
            >
              <CheckBox
                check={printData.IsMultazimNisaabComplete}
                type={"yes"}
              />

              <CheckBox
                check={printData.IsMultazimNisaabComplete}
                type={"no"}
              />
            </div>
          </p>{" "}
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              نقباء کورس
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "35%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.NuqabaCourseDate
                ? moment(printData.NuqabaCourseDate).format("DD/MM/yyyy")
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-35px",
                display: "inline-block",
                width: "50%",
                fontSize: "15px",
              }}
            >
              امراء و نقباء تربیتی و مشاورتی اجتماع
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.UmraNuqabaCourse
                ? moment(printData.UmraNuqabaCourse).format("DD/MM/yyyy")
                : "-"}
            </span>
          </p>{" "}
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginLeft: "-10px",
                display: "inline-block",
                width: "28%",
                fontSize: "15px",
              }}
            >
              ذمہ داران نصاب؟
            </span>
            <div
              style={{
                marginLeft: "20px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60%",
                fontSize: "15px",
                padding: "5px",
              }}
            >
              <CheckBox
                check={printData.IsZimedaranNisaabComplete}
                type={"yes"}
              />

              <CheckBox
                check={printData.IsZimedaranNisaabComplete}
                type={"no"}
              />
            </div>
          </p>{" "}
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              مدرسین کورس
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "35%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MudariseenCourse
                ? moment(printData.MudariseenCourse).format("DD/MM/yyyy")
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-35px",
                display: "inline-block",
                width: "50%",
                fontSize: "15px",
              }}
            >
              مدرسین ریفریشر کورس
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MudariseenRefresherCourse
                ? moment(printData.MudariseenRefresherCourse).format(
                    "DD/MM/yyyy"
                  )
                : "-"}
            </span>
          </p>{" "}
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginLeft: "-10px",
                display: "inline-block",
                width: "28%",
                fontSize: "15px",
              }}
            >
              مدرسین نصاب؟
            </span>
            <div
              style={{
                marginLeft: "20px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60%",
                fontSize: "15px",
                padding: "5px",
              }}
            >
              <CheckBox
                check={printData.IsMudariseenNisaabComplete}
                type={"yes"}
              />

              <CheckBox
                check={printData.IsMudariseenNisaabComplete}
                type={"no"}
              />
            </div>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              عربی گرامر ٹیسٹ
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.ArabicGrammarTestStatus !== null &&
              printData.ArabicGrammarTestStatus
                ? printData.ArabicGrammarTestStatus
                : "نہیں دیا"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                marginRight: "-25px",
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              تجوید ٹیسٹ
            </span>
            <span
              style={{
                marginLeft: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.TajweebTestStatus !== null &&
              printData.TajweebTestStatus
                ? printData.TajweebTestStatus
                : "نہیں دیا"}
            </span>
          </p>{" "}
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-75px",
                display: "inline-block",
                width: "55%",
                fontSize: "15px",
              }}
            >
              راہنما اصول اور نظام العمل کا مطالعہ؟
            </span>
            <div
              style={{
                marginLeft: "20px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "55%",
                fontSize: "15px",
                padding: "5px",
              }}
            >
              <CheckBox
                check={printData.IsReadNizamUlAmalAndRahnumaAsool}
                type={"yes"}
              />

              <CheckBox
                check={printData.IsReadNizamUlAmalAndRahnumaAsool}
                type={"no"}
              />
            </div>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "10%",
                fontSize: "15px",
              }}
            >
              دینی تعلیم
            </span>
            <span
              style={{
                display: "inline-block",
                width: "70%",
                textAlign: "center",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.DeeniEducation !== null &&
              printData.DeeniEducation !== ""
                ? printData.DeeniEducation
                : "-"}
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              className="ur"
              style={{
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              دنیوی تعلیم
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "60%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.DuniyawiEducation !== null &&
              printData.DuniyawiEducation !== ""
                ? printData.DuniyawiEducation
                : "-"}
            </span>
          </p>{" "}
        </div>
      </div>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "20%",
                fontSize: "15px",
              }}
            >
              اضافی قابلیت :
            </span>
            <span
              style={{
                marginRight: "-5px",
                display: "inline-block",
                width: "70%",
                fontSize: "15px",
              }}
            >
              ناظم بیت المال حلقہ / مقامی کے لئے دو پہلوؤں میں اضافی قابلیت
              درکار
            </span>
          </p>{" "}
        </div>
        <div style={section}>
          <div
            style={{
              marginRight: "-50px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              fontSize: "15px",
              padding: "5px",
            }}
          >
            <label
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Check show={printData.KnowledgeAboutAccounts === "true"} />
              <span style={{ marginRight: "5px" }}>
                {" "}
                (i)اکاؤنٹس سے متعلق تعلیم و تجربہ{" "}
              </span>
            </label>

            <label
              style={{
                display: "flex",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Check show={printData.KnowledgeAboutComputerUse === "true"} />
              <span style={{ marginRight: "5px" }}>کمپیوٹر کا استعمال </span>
            </label>
          </div>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        {" "}
        <div style={section}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              fontSize: "15px",
              padding: "5px",
            }}
          >
            <label
              style={{
                display: "flex",
                marginRight: "10px",
                alignItems: "center",
              }}
            >
              <Check
                show={printData.KnowledgeAboutFinancialManual === "true"}
              />
              <span style={{ marginRight: "5px" }}>
                تنظیمی مالیاتی ہدایت نامے (Financial Manual) کا مطالعہ
              </span>
            </label>
          </div>
        </div>{" "}
      </div>
      <br></br>
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "100%",
                fontSize: "15px",
              }}
            >
              ذاتی تربیت / و تعلق مع اللہ:
            </span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "15px",
                display: "inline-block",
                width: "10%",
                fontSize: "15px",
              }}
            >
              عبادات
            </span>
            <span
              style={{
                marginRight: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.Ibadaat !== null && printData.Ibadaat !== ""
                ? printData.Ibadaat
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-20px",
                display: "inline-block",
                width: "10%",
                fontSize: "15px",
              }}
            >
              اخلاقیات
            </span>
            <span
              style={{
                marginRight: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.Ikhlaqiyat !== null && printData.Ikhlaqiyat !== ""
                ? printData.Ikhlaqiyat
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-50px",
                display: "inline-block",
                width: "10%",
                fontSize: "15px",
              }}
            >
              معاملات
            </span>
            <span
              style={{
                marginRight: "20px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.Muamlaat !== null && printData.Muamlaat !== ""
                ? printData.Muamlaat
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-70px",
                display: "inline-block",
                width: "50%",
                fontSize: "15px",
              }}
            >
              مطالعہ لٹریچر / دہرائی
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.Ibadaat !== null && printData.Ibadaat !== ""
                ? printData.Ibadaat
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "100%",
                fontSize: "15px",
              }}
            >
              تمسک بلقرآن :
            </span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-30px",
                display: "inline-block",
                width: "40%",
                fontSize: "15px",
              }}
            >
              تلاوت قرآن
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.TilawatQuran !== null && printData.TilawatQuran !== ""
                ? printData.TilawatQuran
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-25px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              حفظ قرآن
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.HifzQuran !== null && printData.HifzQuran !== ""
                ? printData.HifzQuran
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-35px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              ترجمہ قرآن
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.TarjumaQuran !== null && printData.TarjumaQuran !== ""
                ? printData.TarjumaQuran
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-50px",
                display: "inline-block",
                width: "35%",
                fontSize: "15px",
              }}
            >
              تفسیر قرآن
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.TafseeerQuran !== null &&
              printData.TafseeerQuran !== ""
                ? printData.TafseeerQuran
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "100%",
                fontSize: "15px",
              }}
            >
              دعوت دین :
            </span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-80px",
                display: "inline-block",
                width: "50%",
                fontSize: "15px",
              }}
            >
              انفرادی دعوت کا اہتمام
            </span>
            <span
              style={{
                marginRight: "-10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.InfiradiDawat !== null &&
              printData.InfiradiDawat !== ""
                ? printData.InfiradiDawat
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-75px",
                display: "inline-block",
                width: "60%",
                fontSize: "15px",
              }}
            >
              اجتماعی دعوتی سرگرمیوں میں حصہ
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.AttendanceInIjtimayiDawatiActivities !== null &&
              printData.AttendanceInIjtimayiDawatiActivities !== ""
                ? printData.AttendanceInIjtimayiDawatiActivities
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-40px",
                display: "inline-block",
                width: "50%",
                fontSize: "15px",
              }}
            >
              درس و تدریس / شرکت
            </span>
            <span
              style={{
                marginRight: "-10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.DarsOTadrees !== null && printData.DarsOTadrees !== ""
                ? printData.DarsOTadrees
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "40%",
                fontSize: "15px",
              }}
            >
              انفاق مال و وقت :
            </span>
            <span
              style={{
                marginRight: "-10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.InfaaqMaalWaqt !== null &&
              printData.InfaaqMaalWaqt !== ""
                ? printData.InfaaqMaalWaqt
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "15px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              گھریلو اسره
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.GhareluUsra !== null && printData.GhareluUsra !== ""
                ? printData.GhareluUsra
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-20px",
                display: "inline-block",
                width: "25%",
                fontSize: "15px",
              }}
            >
              متبادل تربیت
            </span>
            <span
              style={{
                marginRight: "-10px",
                display: "inline-block",
                textAlign: "center",
                width: "60%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.MutabadilTarbiat !== null &&
              printData.MutabadilTarbiat !== ""
                ? printData.MutabadilTarbiat
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "20%",
                fontSize: "15px",
              }}
            >
              پابندی نظم :
            </span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-115px",
                display: "inline-block",
                width: "40%",
                fontSize: "15px",
              }}
            >
              (i) اجتماعات میں حاضری
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.AteendanceInIjtimaat !== null &&
              printData.AteendanceInIjtimaat !== ""
                ? printData.AteendanceInIjtimaat
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-60px",
                display: "inline-block",
                width: "55%",
                fontSize: "15px",
              }}
            >
              (ii) ماہانہ / سہ ماہی / ششماہی رپورٹس
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.LfcReports !== null && printData.LfcReports !== ""
                ? printData.LfcReports
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "25%",
                fontSize: "15px",
              }}
            >
              طرز معاشرت :
            </span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-135px",
                display: "inline-block",
                width: "50%",
                fontSize: "15px",
              }}
            >
              (i) الیکٹرانک میڈیا کا استعمال
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.UseOfElectronicMedia !== null &&
              printData.UseOfElectronicMedia !== ""
                ? printData.UseOfElectronicMedia
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-80px",
                display: "inline-block",
                width: "70%",
                fontSize: "15px",
              }}
            >
              (ii)غیر مسنون رسومات میں شرکت سے اجتناب
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "30%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.AvoidGhairMasnoonRasomaat !== null &&
              printData.AvoidGhairMasnoonRasomaat !== ""
                ? printData.AvoidGhairMasnoonRasomaat
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "25%",
                fontSize: "15px",
              }}
            ></span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-135px",
                display: "inline-block",
                width: "40%",
                fontSize: "15px",
              }}
            >
              (iii) شرعی پردہ
            </span>
            <div
              style={{
                marginRight: "-70px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                fontSize: "15px",
                padding: "5px",
              }}
            >
              <label
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Check show={printData.IsSharayiPardaOutsideHome} />

                <span style={{ marginRight: "5px" }}> (ا) گھر سے باہر </span>
              </label>

              <label
                style={{
                  display: "flex",
                  marginRight: "10px",
                  alignItems: "center",
                }}
              >
                <Check show={printData.IsSharayiPardaInHome} />
                <span style={{ marginRight: "5px" }}> (ب) گھر میں </span>
              </label>
            </div>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-100px",
                display: "inline-block",
                width: "75%",
                fontSize: "15px",
              }}
            >
              (iv) غیر محرم و ملحوط محفلوں میں شرکت سے اجتناب
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "30%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.AvoidAttendingMehfilGhairMehram !== null &&
              printData.AvoidAttendingMehfilGhairMehram !== ""
                ? printData.AvoidAttendingMehfilGhairMehram
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "25%",
                fontSize: "15px",
              }}
            ></span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-135px",
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              (v) وضع قطع
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "70%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.PhysicalAppearance !== null &&
              printData.PhysicalAppearance !== ""
                ? printData.PhysicalAppearance
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-100px",
                display: "inline-block",
                width: "45%",
                fontSize: "15px",
              }}
            >
              (vi) رہن سہن (معیار زندگی )
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "60%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.WayOfLiving !== null && printData.WayOfLiving !== ""
                ? printData.WayOfLiving
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "25%",
                fontSize: "15px",
              }}
            >
              معیشت :
            </span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-135px",
                display: "inline-block",
                width: "30%",
                fontSize: "15px",
              }}
            >
              (i) ذریعہ معاش
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.SourceOfIncome !== null &&
              printData.SourceOfIncome !== ""
                ? printData.SourceOfIncome
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-100px",
                display: "inline-block",
                width: "70%",
                fontSize: "15px",
              }}
            >
              (ii) حرام بین و مکروہات تحریمی سے اجتناب
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "40%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.AvoidHaram !== null && printData.AvoidHaram !== ""
                ? printData.AvoidHaram
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                fontWeight: "bold",
                width: "25%",
                fontSize: "15px",
              }}
            ></span>
          </p>
        </div>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "-260px",
                display: "inline-block",
                width: "30%",
                fontSize: "15px",
              }}
            >
              (iii) معاملات (مالی لین دین)
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "100%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.Muamlaat !== null && printData.Muamlaat !== ""
                ? printData.Muamlaat
                : "-"}
            </span>
          </p>
        </div>
      </div>{" "}
      <div style={personalInfo}>
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "40px",
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              دیگر قبل ذکر پہلو (اگر ہوں تو )
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "60%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.OtherInformation !== null &&
              printData.OtherInformation !== ""
                ? printData.OtherInformation
                : "-"}
            </span>
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid #999999",
          marginRight: 60,
          marginLeft: 60,
          height: "130px",
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "15px",
            borderLeft: "1px solid  #999999",
          }}
        >
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  className="ur"
                  style={{
                    display: "inline-block",
                    width: "65%",
                    fontSize: "17px",
                  }}
                >
                  تاثرات مقامی امیر :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "30%",
                    marginRight: 10,
                  }}
                >
                  <u>
                    {moment(
                      convertUTCToLocalTime(printData.DateOfRequest)
                    ).format("DD/MM/yyyy")}
                  </u>
                </span>
              </p>{" "}
            </div>
          </div>{" "}
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "85%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "15px",
                  }}
                >
                  {printData.MuqamiAmeerFeedback !== null &&
                  printData.MuqamiAmeerFeedback !== ""
                    ? printData.MuqamiAmeerFeedback
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "25%",
                    fontSize: "15px",
                  }}
                >
                  نام مقامی امیر :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "60%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "15px",
                  }}
                >
                  {printData.MuqamiAmeerName !== null &&
                  printData.MuqamiAmeerName !== ""
                    ? printData.MuqamiAmeerName
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            padding: "15px",
          }}
        >
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  className="ur"
                  style={{
                    display: "inline-block",
                    width: "65%",
                    fontSize: "17px",
                  }}
                >
                  توثیق :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "30%",
                    marginRight: 10,
                  }}
                >
                  <u>
                    {moment(new Date()).format(
                      "DD/MM/yyyy"
                    )}
                  </u>
                </span>
              </p>{" "}
            </div>
          </div>
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "85%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "15px",
                  }}
                >
                  {printData.AmeerHalqaFeedback !== null &&
                  printData.AmeerHalqaFeedback !== ""
                    ? printData.AmeerHalqaFeedback
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
          <br></br>
          <div style={personalInfo}>
            <div style={section}>
              {" "}
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "25%",
                    fontSize: "15px",
                  }}
                >
                  نام امیر حلقہ :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "60%",
                    textAlign: "center",
                    borderBottom: "1px dotted black",
                    fontSize: "17px",
                  }}
                >
                  {printData.AmeerHalqaName !== null &&
                  printData.AmeerHalqaName !== ""
                    ? printData.AmeerHalqaName
                    : "-"}
                </span>
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          border: "1px solid #999999",
          marginRight: 60,
          marginLeft: 60,
          height: "50px",
          padding: 20,
        }}
      >
        <div style={section}>
          <p>
            <span
              style={{
                display: "inline-block",
                width: "35%",
                fontSize: "15px",
              }}
            >
              تبصرہ و تاثرات :
            </span>
            <span
              style={{
                marginRight: "10px",
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.NazimAllaTabsaraOTasuraat !== null &&
              printData.NazimAllaTabsaraOTasuraat !== ""
                ? printData.NazimAllaTabsaraOTasuraat
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "20px",
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              ناظم اعلی
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {printData.NazimAllaName !== null &&
              printData.NazimAllaName !== ""
                ? printData.NazimAllaName
                : "-"}
            </span>
          </p>
        </div>{" "}
        <div style={section}>
          {" "}
          <p>
            <span
              style={{
                marginRight: "20px",
                display: "inline-block",
                width: "20%",
                fontSize: "15px",
              }}
            >
              تاریخ
            </span>
            <span
              style={{
                display: "inline-block",
                textAlign: "center",
                width: "50%",
                borderBottom: "1px dotted black",
                fontSize: "15px",
              }}
            >
              {moment(new Date()).format("DD/MM/yyyy")}
            </span>
          </p>
        </div>
      </div>
      <br></br>
      <div
        style={{
          ...personalInfo,
          ...textStyle,
          marginRight: "60px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          نوٹ :
        </span>{" "}
        مقامی و حلقہ جاتی معاونین کے تقرر کے وقت یہ بھی ضروریہے کہ ملتزم قرار
        پائے کم از کم 2 سال گزر چکے ہوں اور ملتزم تربیتی کورس میں بھی شرکت مکمل
        ہو
      </div>
    </div>
  );
};

export default PrintMuavaneenForm;

import React, { useEffect, useState } from "react";
import { convertUTCToLocalTime } from "../../../constants/const";
import moment from "moment";
import { Button, Table } from "reactstrap";
import {
  GetCustomStyles,
  GetPeriodName,
  GetSelectedLanguage,
} from "../../helper/Method";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { PiEyeDuotone } from "react-icons/pi";

const MemberLetterForCounselling = ({
  tab3,
  history,
  memberId,
  letterForCounsellingList,
  memberValues,
}) => {
  const [unitNames, setUnitNames] = useState([]);
  useEffect(() => {
    let tempNames = [];

    letterForCounsellingList.forEach((element) => {
      tempNames.push({
        names: element.unitName.split("،"),
      });
    });

    setUnitNames(tempNames);
  }, [letterForCounsellingList]);

  return (
    <div role="tabpanel" id="tab-3" className={"tab-pane " + tab3}>
      <div className="panel-body">
        {letterForCounsellingList.length !== 0 ? (
          <div className="table-responsive">
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{<GetLanguageString props="member_reg_number" />}</th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_letter_for_counselling_title" />
                    }
                  </th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_responsibility_history" />
                    }
                  </th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_unit_history" />
                    }
                  </th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_letter_for_counselling_received_date" />
                    }
                  </th>
                  <th className="text-center">
                    {<GetLanguageString props="common_action" />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {letterForCounsellingList.map((record, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{record.regNo}</td>
                      <td className="ur">
                        {record.title} (
                        {GetPeriodName(
                          record.letterFrequency,
                          record.letterForFrequency
                        )}{" "}
                        {record.year})
                      </td>
                      <td>
                        {record.responsibility.map((item, index) => {
                          return (
                            <Button
                              size="xs"
                              color="info"
                              key={index}
                              className="label label-info m-r-xs"
                            >
                              <h5 className="ur">{item}</h5>
                            </Button>
                          );
                        })}
                      </td>

                      <td className="ur">
                        <ol className="breadcrumb">
                          {unitNames[index]?.names?.map((item, index) => {
                            return (
                              <li
                                className={
                                  GetSelectedLanguage() === "ur"
                                    ? "breadcrumb-item-ur"
                                    : "breadcrumb-item"
                                }
                                key={index}
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ol>
                      </td>
                      <td>
                        {record.receivedAt
                          ? moment(
                              convertUTCToLocalTime(record.receivedAt)
                            ).format("DD/MM/yyyy")
                          : null}
                      </td>
                      <td className="text-center">
                        <Button
                          color="white"
                          size="sm"
                          onClick={() => {
                            history.push({
                              pathname: `/member/viewLetterForCounselling/${memberId}`,
                              state: {
                                memberId: memberId,
                                letterForCounselling: record.title,
                                receivedLetterForCounsellingId: record.id,
                                memberValues: memberValues,
                                letterFrequency: record.letterFrequency,
                                letterForFrequency: record.letterForFrequency,
                                letterYear: record.year,
                                receivedAt: record.receivedAt,
                                calledAs: "view",
                              },
                            });
                          }}
                        >
                          <PiEyeDuotone
                            color="#1A7BB9"
                            size={15}
                            className={GetCustomStyles().fa_style}
                          />
                          <GetLanguageString props="member_view" />
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <NoResult />
        )}
      </div>
    </div>
  );
};

export default withRouter(MemberLetterForCounselling);
